import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  background-image: linear-gradient(#2281E2, #5BD0ED);
  background-repeat: repeat-x;
  width: 100%;
  height: calc(100vh - 148px);
`;
