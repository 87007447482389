import styled from 'styled-components';

import bgLogin from '../../assets/images/login/box.png';
import bgContainer from '../../assets/images/login/background.png';

export const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${bgContainer}');
`;

export const BgLogin = styled.div`
  width: 100%;
  max-width: 800px;
  height: 570px;
  display: flex;
  justify-content: center;
  background-image: url('${bgLogin}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 20px auto;
  box-sizing: border-box;
  border-radius: 30px 30px 48px 48px;
`;

export const LoginContainer = styled.div`
  width: 100%;  
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  
`;

export const LogoBemVindo = styled.img`
  width: 100%;
  max-width: 400px;
  margin: 24px auto 0 auto;
`;
