import React, { useState } from 'react';

import Game from './Game';
import StartGame from './StartGame';

import GameScene from '../Games/GameScene';

const GeniusGame: React.FC = () => {
  const [gameStarted, setGameStarted] = useState<boolean>(false);

  const startScreen = <StartGame startGame={() => setGameStarted(true)} />;
  const gameScreen = <Game />;

  return (
    <GameScene
      background="#7e0a52"
      startScreen={startScreen}
      gameScreen={gameScreen}
      gameStarted={gameStarted}
      fullWidth
      fullHeight
    />
  );
};

export default GeniusGame;
