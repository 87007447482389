enum GeniusGameStatusEnum {
  WAITING_START,
  EXECUTING_TURN,
  FINISHING_TURN,
  WAITING_USER,
  USER_WON,
  USER_LOST,
}

export default GeniusGameStatusEnum;
