import React from 'react';
import Video from './styles';

const VideoComponent = (props: { src?:string }) => {
  const baseURL = process.env.NODE_ENV === 'development'
    ? 'https://interacaovirtual.com.br/mashaeourso'
    : process.env.PUBLIC_URL;

  const { src } = props;

  const handlePlay = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const videos = document.querySelectorAll('video');

    videos.forEach((v:HTMLVideoElement) => {
      const elem = v;

      if (e.target !== elem && !elem.paused) {
        elem.pause();
        elem.currentTime = 0;
      }
    });
  };

  return (
    <Video controls onPlay={handlePlay}>
      <source type="video/mp4" src={`${baseURL}${src}`} />
    </Video>
  );
};

export default VideoComponent;
