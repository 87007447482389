import React from 'react';

import PageContainer from './styles';

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => (
  <PageContainer className="h-100 d-flex flex-column">
    {children}
  </PageContainer>
);
