import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import TreasureHunt from '../../components/TreasureHunt';

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (): {} => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const TreasureHuntPage: (props: Props) => JSX.Element = () => (<TreasureHunt />);

export default connector(TreasureHuntPage);
