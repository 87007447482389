import React, { useState } from 'react';
import useInterval from 'use-interval';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import {
  executeTurn as executeTurnAction,
  playNote as playNoteAction,
  stopPlayNote as stopPlayNoteAction,
  selectNote as selectNoteAction,
  waitForUser as waitForUserAction,
} from '../../../store/slices/GeniusGame';

import GameControls from './GameControls';
import NoteButton from './NoteButton';
import NoteButtonsContainer from './ButtonsContainer';
import GameControlsContainer from './GameControlsContainer';
import GeniusGameStatusEnum from '../../../types/GeniusGame/GeniusGameStatusEnum';
import {
  EndAlignedCol, EndAlignedRightCol, FullHeightRow, MashaImg, AmpImg,
} from './styles';

import AmpImage from '../../../assets/images/genius-game/amp.png';
import MashaImage from '../../../assets/images/genius-game/masha.png';

const GeniusGame: () => JSX.Element = () => {
  const dispatch = useAppDispatch();

  const status = useAppSelector((state) => state.genius.status);
  const gameNotes = useAppSelector((state) => state.genius.notes);

  const [shouldRunInterval, setShouldRunInterval] = useState<boolean>(false);

  let index = 0;
  let playing = true;

  React.useEffect(() => {
    const statuses = [GeniusGameStatusEnum.EXECUTING_TURN, GeniusGameStatusEnum.FINISHING_TURN];
    setShouldRunInterval(statuses.includes(status));
  }, [status]);

  useInterval(() => {
    if (status === GeniusGameStatusEnum.FINISHING_TURN) {
      dispatch(executeTurnAction());
    } else {
      if (playing) {
        dispatch(playNoteAction(index));
      } else {
        dispatch(stopPlayNoteAction());
        index += 1;
      }

      if (index >= gameNotes.length) {
        dispatch(waitForUserAction());
      }

      playing = !playing;
    }
  }, shouldRunInterval ? 500 : null);

  const executeTurn = () => {
    dispatch(executeTurnAction());
  };

  const onGameStarted = () => {
    executeTurn();
  };

  const onNoteClicked = (position: number) => {
    dispatch(selectNoteAction(position));
  };

  return (
    <>
      <NoteButtonsContainer>
        <NoteButton color="#e3037e" position={0} note={392.0} onClick={onNoteClicked} />
        <NoteButton color="#5dc2eb" position={1} note={293.7} onClick={onNoteClicked} />
        <NoteButton color="#ffea00" position={2} note={329.6} onClick={onNoteClicked} />
      </NoteButtonsContainer>

      <GameControlsContainer>
        <FullHeightRow>
          <EndAlignedCol className="d-flex flex-column" xxl={3} lg={3} xs={3}>
            <AmpImg src={AmpImage} alt="Amplificador" />
          </EndAlignedCol>
          <EndAlignedCol xxl={6} lg={6} xs={6}>
            <GameControls onGameStarted={onGameStarted} />
          </EndAlignedCol>
          <EndAlignedRightCol className="d-flex flex-column" xxl={3} lg={3} xs={3}>
            <MashaImg src={MashaImage} alt="Masha" />
          </EndAlignedRightCol>
        </FullHeightRow>
      </GameControlsContainer>
    </>
  );
};

export default GeniusGame;
