import React from 'react';

import LogoFacebook from '../../../assets/images/home/facebook.png';
import LogoInstagram from '../../../assets/images/home/instagram.png';
import LogoYoutube from '../../../assets/images/home/yt.png';

import { FooterStyled, Logo } from './styles';

const Footer: React.FC = () => (
  <FooterStyled>
    <p className="mb-0">
      &copy;2008 Animaccord LTD.

      <a href="https://www.instagram.com/mashaandthebear" target="_blank" rel="noreferrer">
        <Logo className="ms-2" alt="Instagram" src={LogoInstagram} />
      </a>

      <a href="https://www.facebook.com/MashaeoUrso" target="_blank" rel="noreferrer">
        <Logo className="mx-2" alt="Facebook" src={LogoFacebook} />
      </a>

      <a href="https://www.youtube.com/channel/UCJKBSfD5JSUxGhriFeoPCCg" target="_blank" rel="noreferrer">
        <Logo className="me-2" alt="Youtube" src={LogoYoutube} />
      </a>
    </p>
  </FooterStyled>
);

export default Footer;
