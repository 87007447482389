import React from 'react';

import { useAppSelector } from '../../../../store/hooks';
import GeniusGameStatusEnum from '../../../../types/GeniusGame/GeniusGameStatusEnum';

import { GameControlsContainer, PlayButton, TurnLabel } from './styles';
import PlayImage from '../../../../assets/images/genius-game/play.png';

interface Props {
  onGameStarted: () => void;
}

const GameControlsComponent: (props: Props) => JSX.Element = (props: Props) => {
  const status = useAppSelector((state) => state.genius.status);
  const turn = useAppSelector((state) => state.genius.turn);

  const gameIsWaiting = status === GeniusGameStatusEnum.WAITING_START;
  const userWaiting = status === GeniusGameStatusEnum.WAITING_USER;
  const userWon = status === GeniusGameStatusEnum.USER_WON;
  const userLost = status === GeniusGameStatusEnum.USER_LOST;

  const onStartClicked = () => {
    props.onGameStarted();
  };

  return (
    <GameControlsContainer>
      {gameIsWaiting && (
      <PlayButton type="button" onClick={onStartClicked}>
        <img src={PlayImage} alt="Começar" />
      </PlayButton>
      )}
      {!gameIsWaiting && !(userWon || userLost) && (
      <TurnLabel>
        Rodada&nbsp;
        {turn}
      </TurnLabel>
      )}
      {userWaiting && (
      <span>
        Reproduza a sequência
      </span>
      )}
      {userWon && <span>Você ganhou!</span>}
      {userLost && <span>Você perdeu :(</span>}
    </GameControlsContainer>
  );
};

export default GameControlsComponent;
