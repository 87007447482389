import styled, { css } from 'styled-components';
import StyledProps from './styled-props';

export default styled.img<StyledProps>`
  cursor: pointer;
  max-width: 100%;
  ${(props) => props.rotate && css`
    transform: rotate(${props.rotate}deg);
  `}
`;
