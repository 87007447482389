import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
`;

export const PartnerCard = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PartnerImg = styled.img`
    
`;

export const LoadingContainer = styled.div`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > .spinner-border {
    color: #01e3be;
  }
`;
