import React, { useCallback, useEffect } from 'react';

import MazeContainer from './MazeContainer';
import { useAppDispatch } from '../../../store/hooks';

import MazeCanvas from './MazeCanvas';
import MazeControls from './GameControls';
import Direction from '../../../types/MazeGame/Direction';
import { choosePath as choosePathAction, restart as restartAction } from '../../../store/slices/MazeGame';

const MazeGame: () => JSX.Element = () => {
  const dispatch = useAppDispatch();

  const keysMap: { [key: number]: Direction; } = {
    38: Direction.UP,
    40: Direction.DOWN,
    37: Direction.LEFT,
    39: Direction.RIGHT,
  };

  const onDirectionClicked = (direction: Direction) => {
    dispatch(choosePathAction(direction));
  };

  const onRestartClicked = () => {
    dispatch(restartAction());
  };

  const escFunction = useCallback((event) => {
    const direction = keysMap[event.keyCode];

    if (direction !== null && direction !== undefined) {
      onDirectionClicked(direction);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <>
      <MazeContainer>
        <MazeCanvas />
      </MazeContainer>

      <MazeControls onDirectionClicked={onDirectionClicked} onRestartClicked={onRestartClicked} />
    </>
  );
};

export default MazeGame;
