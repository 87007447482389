import React from 'react';

import {
  GenericSquare, MashaSquare, BearSquare, MashaImage, BearImage,
} from './styles';

interface Props {
  value: string
  onClick: () => void
}

const Square: React.FC<Props> = ({ value, onClick }: Props) => {
  const isMasha = value === 'masha';
  const isBear = value === 'bear';
  const isGenericSquare = value === null;
  return (
    <>
      {isGenericSquare && (
      <GenericSquare onClick={onClick} />
      )}
      {isBear && (
      <BearSquare>
        <BearImage />
      </BearSquare>
      )}
      {isMasha && (
        <MashaSquare>
          <MashaImage />
        </MashaSquare>
      )}
    </>
  );
};

export default Square;
