import React from 'react';
import { RouteComponentProps } from 'react-router';

import EpisodesTitle from '../../assets/images/episodes/title.png';

import episodes from './data';
import { Params } from '../../types/Episodes/Params';
import StaticPage from '../Common/Containers/StaticPage';
import StaticPageTitle from '../Common/StaticPageTitle';

import { Subtitle, VideoYT } from './styles';

import QuestionContainer from './QuestionContainer';

const Quiz: React.FC<RouteComponentProps<Params>> = (props: RouteComponentProps<Params>) => {
  const { match } = props;
  const episodeId = Number(match.params.id);

  const episode = episodes.find((e) => e.id === episodeId);

  const title = <StaticPageTitle className="mb-5" img={EpisodesTitle} title="Episódios" />;

  if (!episode) {
    return <></>;
  }

  return (
    <StaticPage title={title}>

      <Subtitle dangerouslySetInnerHTML={{ __html: episode.title }} />

      <VideoYT
        width="100"
        height="315"
        src={episode.videoUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mx-auto"
      />

      <Subtitle>HORA DO QUIZ!</Subtitle>

      { episode.quiz.map((a, index) => (<QuestionContainer questionRow={a} index={index} />)) }

    </StaticPage>
  );
};

export default Quiz;
