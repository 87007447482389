import styled from 'styled-components';

export const Board = styled.div`
  position: relative;
  width: 615px;
  height: 460px;
  left: 87px;
  background-color: #fff;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {};
