import React from 'react';
import StaticPage from '../Common/Containers/StaticPage';
import StaticPageTitle from '../Common/StaticPageTitle';

import GamesTitle from '../../assets/images/games/title.png';

import StaticPageButton from '../Common/StaticPageButton';
import VerticallyCenteredContainer from '../Common/Containers/VerticallyCenteredContainer';

const Games: () => JSX.Element = () => (
  <StaticPage>
    <VerticallyCenteredContainer>
      <StaticPageTitle img={GamesTitle} title="Jogos" />

      <StaticPageButton route="/jogos/caca-ao-tesouro">Caça ao Tesouro</StaticPageButton>
      <StaticPageButton route="/jogos/jogo-musical-da-memoria">Jogo da Memória Musical</StaticPageButton>
      <StaticPageButton route="/jogos/jogo-da-memoria">Jogo da Memória</StaticPageButton>
      <StaticPageButton route="/jogos/pegue-as-frutas">Pegue as Frutas</StaticPageButton>
      <StaticPageButton route="/jogos/super-masha">Super Masha</StaticPageButton>
    </VerticallyCenteredContainer>
  </StaticPage>
);

export default Games;
