/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import {
  Container, RestartButton, GameContainer, WinnerTitle, WinnerSubTitle,
} from './styles';

import BunnyImg from '../../../assets/images/memory-game/card-1.png';
import BearImg from '../../../assets/images/memory-game/card-2.png';
import PorkImg from '../../../assets/images/memory-game/card-3.png';
import MashaImg from '../../../assets/images/memory-game/card-4.png';
import PandaImg from '../../../assets/images/memory-game/card-5.png';
import PenguinImg from '../../../assets/images/memory-game/card-6.png';
import Grid from './Grid';

const useInterval = (callback: any, delay: any, duration: any) => {
  const durationRef = useRef(duration);
  const durationIntervalRef = useRef<any>();

  const handler = () => {
    callback(durationRef);
  };

  React.useEffect(() => {
    const durationInterval = setInterval(handler, delay);
    durationIntervalRef.current = durationInterval;
    return () => {
      clearInterval(durationInterval);
    };
  }, [delay]);

  return durationIntervalRef;
};

const Game = () => {
  const [newGame, setNewGame] = useState(false);
  const [list, setList] = useState<
  { id: number; img: any; description: string }[]
  >([]);
  const [visibleItems, setVisibleItems] = useState<any[]>([]);
  const [duration, setDuration] = useState(0);
  const [finishedItems, setFinishedItems] = useState<number[]>([]);
  const [winner, setWinner] = useState(false);

  const checkItems = (firstIndex: number, secondIndex: number) => {
    if (
      firstIndex !== secondIndex
      && list[firstIndex].img === list[secondIndex].img
    ) {
      setFinishedItems([...finishedItems, firstIndex, secondIndex]);
    } else {
      setTimeout(() => {
        setVisibleItems([]);
      }, 600);
    }
  };

  React.useEffect(() => {
    const newList = [
      { id: 1, img: BunnyImg, description: 'bunny' },
      { id: 2, img: BearImg, description: 'bear' },
      { id: 3, img: PorkImg, description: 'pork' },
      { id: 4, img: MashaImg, description: 'masha' },
      { id: 5, img: PandaImg, description: 'panda' },
      { id: 6, img: PenguinImg, description: 'penguin' },
    ];
    setList(
      newList
        .concat(
          newList.map((item) => ({
            ...item,
            id: (item.id + newList.length),
          })),
        )
        .sort(() => 0.5 - Math.random()),
    );
  }, [newGame]);

  const durationIntervalRef = useInterval(
    (durationRef: { current: number }) => {
      const d = durationRef;
      d.current += 1;
      setDuration(d.current);
    },
    1000,
    duration,
  );

  React.useEffect(() => {
    if (finishedItems.length > 0 && finishedItems.length === list.length) {
      setWinner(true);
      clearInterval(durationIntervalRef.current);
    }
  }, [finishedItems]);

  return (
    <div className="d-flex flex-column">
      {winner && (
        <>
          <WinnerTitle>
            Você Ganhou!
          </WinnerTitle>
          <WinnerSubTitle>
            Você levou
            {' '}
            {duration}
            {' '}
            segundos para completar
          </WinnerSubTitle>
        </>
      )}
      <Container>
        <GameContainer>
          <Grid
            list={list}
            visibleItems={visibleItems}
            setVisibleItems={setVisibleItems}
            finishedItems={finishedItems}
            checkItems={checkItems}
          />
        </GameContainer>
        <RestartButton
          onClick={() => {
            setList([]);
            setNewGame(!newGame);
            setVisibleItems([]);
            setFinishedItems([]);
            setWinner(false);
          }}
        />
      </Container>
    </div>
  );
};

export default Game;
