import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import GeniusGameStatusEnum from '../../types/GeniusGame/GeniusGameStatusEnum';
import GeniusGameState from '../../types/GeniusGame/GeniusGameState';

const initialState: GeniusGameState = {
  turn: 0,
  points: 0,
  notes: [],
  selectedNotes: [],
  noteBeingPlayed: null, // Index of note being played
  status: GeniusGameStatusEnum.WAITING_START,
};

const generateRandomNumber = () => Math.floor(Math.random() * 3);

const executeTurnReducer = (draft: Draft<GeniusGameState>) => {
  draft.status = GeniusGameStatusEnum.EXECUTING_TURN;
  draft.turn += 1;
  draft.notes = [...draft.notes, generateRandomNumber()];
  draft.selectedNotes = [];
};

export const geniusGameSlice = createSlice({
  name: 'geniusGame',
  initialState,
  reducers: {
    executeTurn: executeTurnReducer,
    playNote: (draft, action: PayloadAction<number>) => {
      draft.noteBeingPlayed = action.payload;
    },
    stopPlayNote: (draft) => {
      draft.noteBeingPlayed = null;
    },
    waitForUser: (draft) => {
      draft.noteBeingPlayed = null;
      draft.status = GeniusGameStatusEnum.WAITING_USER;
    },
    selectNote: (draft, action: PayloadAction<number>) => {
      draft.selectedNotes = [...draft.selectedNotes, action.payload];

      const lastPlayedNote = draft.notes[draft.selectedNotes.length - 1];

      if (lastPlayedNote !== action.payload) {
        draft.status = GeniusGameStatusEnum.USER_LOST;
      }

      if (draft.selectedNotes.length === draft.notes.length) {
        const predicate = (n: number, idx: number) => draft.selectedNotes[idx] === n;
        const userMatchAllNotes = draft.notes.every(predicate);

        if (userMatchAllNotes) {
          draft.status = GeniusGameStatusEnum.FINISHING_TURN;
        }
      }
    },
  },
});

export const {
  executeTurn, playNote, stopPlayNote, waitForUser, selectNote,
} = geniusGameSlice.actions;
export default geniusGameSlice.reducer;
