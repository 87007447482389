import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import HealthyEating from '../../components/HealthyEating';

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (): {} => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const HealthyEatingGame: (props: Props) => JSX.Element = () => (<HealthyEating />);

export default connector(HealthyEatingGame);
