import React from 'react';

import { RouteComponentProps } from 'react-router';
import Login from '../../components/Login';

type LoginPageType = (props: RouteComponentProps) => JSX.Element;

const LoginPage: LoginPageType = (props: RouteComponentProps) => (<Login {...props} />);

export default LoginPage;
