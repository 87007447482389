import React from 'react';
import GameContainerProps from './props';
import { Container, GameContainer } from './styles';

export default (props: GameContainerProps) => {
  const { children, fullWidth, ...rest } = props;
  return (
    <Container {...rest}>
      <GameContainer fullWidth={fullWidth}>
        {children}
      </GameContainer>
    </Container>
  );
};
