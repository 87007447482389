import React, { useState } from 'react';

import { IonPhaser } from '@ion-phaser/react';
import { Container, GameContainerStyled } from './styles';

import MainScene from './scenes/main-scene';

import bg from '../../../assets/images/healthy-food/background.png';

interface Props {
  onGameOver: (frutasColetadas:number) => void;
  frutasColetadas: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HealthyEating = ({ onGameOver, frutasColetadas }: Props) => {
  const mainScene = new MainScene(onGameOver);

  const [game] = useState({
    width: 1024,
    height: 720,
    type: Phaser.CANVAS,
    scene: mainScene,
    transparent: true,
    physics: {
      default: 'arcade',
      arcade: {
        debug: false,
        gravity: {
          y: 0,
        },
      },
    },
  });

  return (
    <GameContainerStyled backgroundimage={bg}>
      <Container>
        <IonPhaser game={game} />
      </Container>
    </GameContainerStyled>
  );
};

export default HealthyEating;
