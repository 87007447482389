import React from 'react';

import GameContainer from '../GameContainer';
import GameSceneProps from './props';

const GameScene: React.FC<GameSceneProps> = (props: GameSceneProps) => {
  const {
    startScreen, gameScreen, gameStarted, fullWidth, fullHeight, ...rest
  } = props;

  return (
    <GameContainer {...rest} fullWidth={fullWidth}>
      <div className={`d-flex flex-row justify-content-center w-100 ${fullHeight ? 'flex-grow-1' : ''} ${fullWidth ? 'p-0' : ''}`}>
        {gameStarted ? gameScreen : startScreen}
      </div>
    </GameContainer>
  );
};

export default GameScene;
