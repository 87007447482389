import styled from 'styled-components';

export const StyledScore = styled.p`
    width: 150px;
    font-size: 100px;
    font-weight: bold;
    color: #1d1b3e;
    text-align: center;
    z-index: 6;
    line-height: 1;
    top: 210px;
    left: 590px;
    position: absolute;
`;

export const StyledLabel = styled.p`
    font-size: 45px;
    font-weight: bold;
    color: #1d1b3e;
    text-align: center;
    z-index: 6;
    line-height: 1;
    top: 320px;
    left: 475px;
    position: absolute;
`;
