import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import MazeGameState from '../../types/MazeGame/MazeGameState';
import Direction from '../../types/MazeGame/Direction';
import MazeGameStatusEnum from '../../types/MazeGame/MazeGameStatusEnum';

import initialMap from '../state/MazeGame';
import MazePathMap from '../../types/MazeGame/MazePathMap';
import MazePathLine from '../../types/MazeGame/MazePathLine';

const lineOffset = 0.002235;

const initialState: MazeGameState = {
  status: MazeGameStatusEnum.WAITING_TURN,
  lineWidth: 4,
  initialPosition: {
    x: 0.1300,
    y: 0.3700,
  },
  map: initialMap,
  path: [],
};

const getLastPath = (state: Draft<MazeGameState>) => {
  const { path } = state;

  const isNotFirstMovement = path.length > 0;
  return isNotFirstMovement ? path[path.length - 1] : null;
};

const calculateToX = (currentX: number, nextPath: MazePathMap) => {
  const { direction } = nextPath;

  if (direction === Direction.RIGHT) {
    return currentX + nextPath.size + lineOffset;
  } if (direction === Direction.LEFT) {
    return currentX - nextPath.size;
  }

  return currentX;
};

const calculateToY = (currentY: number, nextPath: MazePathMap) => {
  const { direction } = nextPath;

  if (direction === Direction.UP) {
    return currentY - nextPath.size;
  } if (direction === Direction.DOWN) {
    return currentY + nextPath.size;
  }

  return currentY;
};

const calculateFromX = (state: Draft<MazeGameState>, nextPath: MazePathMap) => {
  const lastPath = getLastPath(state);

  if (!lastPath) {
    return state.initialPosition.x;
  }

  if (lastPath.direction === nextPath.direction) {
    return lastPath.to.x;
  }

  const currentX = lastPath.to.x;

  if (nextPath.finish) {
    return currentX - lineOffset;
  }

  if (lastPath.direction === Direction.UP || lastPath.direction === Direction.LEFT) {
    return currentX + lineOffset;
  }

  if (nextPath.direction === Direction.LEFT) {
    return currentX + lineOffset;
  }

  return currentX - lineOffset;
};

const calculateFromY = (state: Draft<MazeGameState>, nextPath: MazePathMap) => {
  const lastPath = getLastPath(state);

  if (!lastPath) {
    return state.initialPosition.y;
  }

  if (lastPath.direction === nextPath.direction) {
    return lastPath.to.y;
  }

  const currentY = lastPath.to.y;

  if (lastPath.direction === Direction.UP || lastPath.direction === Direction.LEFT) {
    return currentY + lineOffset;
  }

  /* if (nextPath.direction === Direction.RIGHT) {
    return currentY + lineOffset;
  } */

  return currentY - (lineOffset);
};

const createLine = (state: Draft<MazeGameState>, nextPath: MazePathMap): MazePathLine => {
  const fromX = calculateFromX(state, nextPath);
  const fromY = calculateFromY(state, nextPath);

  return {
    from: {
      x: fromX,
      y: fromY,
    },
    to: {
      x: calculateToX(fromX, nextPath),
      y: calculateToY(fromY, nextPath),
    },
    direction: nextPath.direction,
    drawn: false,
  };
};

export const mazeGameSlice = createSlice({
  name: 'geniusGame',
  initialState,
  reducers: {
    restart: (draft: Draft<MazeGameState>) => {
      draft.path = [];
      draft.currentMapPosition = undefined;
      draft.status = MazeGameStatusEnum.WAITING_TURN;
    },
    setLastLineAsDrawn: (draft: Draft<MazeGameState>) => {
      draft.path[draft.path.length - 1].drawn = true;

      if (![MazeGameStatusEnum.USER_WON, MazeGameStatusEnum.USER_LOST].includes(draft.status)) {
        draft.status = MazeGameStatusEnum.WAITING_TURN;
      }
    },
    choosePath: (draft: Draft<MazeGameState>, payload: PayloadAction<Direction>) => {
      const { status, currentMapPosition, map } = draft;
      if (status !== MazeGameStatusEnum.WAITING_TURN) {
        return;
      }

      const searchArray = currentMapPosition?.children || map;
      const nextPath = searchArray.find((i) => i.direction === payload.payload);

      if (!nextPath) {
        draft.status = MazeGameStatusEnum.USER_LOST;
      } else {
        const line = createLine(draft, nextPath);

        draft.currentMapPosition = nextPath;
        draft.path.push(line);

        if (nextPath.finish) {
          draft.status = MazeGameStatusEnum.USER_WON;
        } else if (nextPath.children.length === 0) {
          draft.status = MazeGameStatusEnum.USER_LOST;
        } else {
          draft.status = MazeGameStatusEnum.DRAWING_LINE;
        }
      }
    },
  },
});

export const { choosePath, restart, setLastLineAsDrawn } = mazeGameSlice.actions;
export default mazeGameSlice.reducer;
