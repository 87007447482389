import styled, { css } from 'styled-components';
import CardHiddenImg from '../../../../assets/images/memory-game/card-hidden.png';

type Props = {
  showing: boolean;
};

export const GridCard = styled.div<Props>`
  position: relative;

  background-color: transparent;
  perspective: 1000px;

  ${(props) => props.showing && css`
    > .inner {
      transform: rotateY(180deg);
    }
  `}
`;

export const CardContainer = styled.div`
  position: relative;
  height: 127px;
  width: 128px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  
  > * {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transition: .4s;
  }
`;

export const CardHidden = styled.div`
  background: url(${CardHiddenImg}) no-repeat;
`;

export const CardShow = styled.img`
  transform: rotateY(180deg);
`;
