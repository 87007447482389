import React from 'react';
import { RouteComponentProps } from 'react-router';

import Quiz from '../../components/Quiz';
import { Params } from '../../types/Episodes/Params';

type PropsType = RouteComponentProps<Params>;
type EpisodeQuizPageType = (props: PropsType) => JSX.Element;

const EpisodeQuizPage: EpisodeQuizPageType = (props: PropsType) => <Quiz {...props} />;

export default EpisodeQuizPage;
