import React from 'react';

import { Container, Image } from './styles';

const MashaContainer: React.FC = () => (
  <Container>
    <Image />
  </Container>
);

export default MashaContainer;
