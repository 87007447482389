import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LoginState from '../../types/Login/LoginState';

const initialState: LoginState = {
  token: localStorage.getItem('token') || null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setToken: (draft, action: PayloadAction<string>) => {
      draft.token = action.payload;
    },
  },
});

export const {
  setToken,
} = loginSlice.actions;
export default loginSlice.reducer;
