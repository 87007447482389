import styled from 'styled-components';

export default styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 767px) {
    display: block
  }
`;
