import Episodes from '../../types/Episodes/Episode';

const data: Episodes[] = [
  {
    id: 1,
    title: '&#129336; Desafio de Dança &#128131;',
    videoUrl: 'https://www.youtube.com/embed/IVPIa1Sdb6s',
    quiz: [
      {
        question: 'Como a Masha e o Urso receberam a mensagem da visita da Rosita?',
        answers: [
          {
            correct: false,
            description: 'Whatsapp',
          },
          {
            correct: true,
            description: 'Telegrama',
          },
          {
            correct: false,
            description: 'Ligação',
          },
        ],
      },
      {
        question: 'De qual lugar foi enviado o telegrama para o Urso?',
        answers: [
          {
            correct: false,
            description: 'Japão',
          },
          {
            correct: false,
            description: 'Argentina',
          },
          {
            correct: true,
            description: 'Brasil',
          },
        ],
      },
      {
        question: 'Como a Rosita chegou na floresta?',
        answers: [
          {
            correct: false,
            description: 'Avião',
          },
          {
            correct: true,
            description: 'Trem',
          },
          {
            correct: false,
            description: 'Moto',
          },
        ],
      },
      {
        question: 'Qual festa estavam celebrando?',
        answers: [
          {
            correct: false,
            description: 'Festa Junina',
          },
          {
            correct: true,
            description: 'Carnaval',
          },
          {
            correct: false,
            description: 'Natal',
          },
        ],
      },
      {
        question: 'Por que a Rosita foi embora correndo da floresta?',
        answers: [
          {
            correct: true,
            description: 'Estava atrasada, o trem já ia partir',
          },
          {
            correct: false,
            description: 'Estava com sono e queria dormir',
          },
          {
            correct: false,
            description: 'Começou a chover',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Dia de Geleia &#127826;',
    videoUrl: 'https://www.youtube.com/embed/JHlT5lVcmWE',
    quiz: [
      {
        question: 'Qual é a cor da geleia que o urso derrubou?',
        answers: [
          {
            correct: false,
            description: 'Amarela',
          },
          {
            correct: true,
            description: 'Vermelha',
          },
          {
            correct: false,
            description: 'Azul',
          },
        ],
      },
      {
        question: 'Qual parte do corpo do urso ficou presa no pote?',
        answers: [
          {
            correct: false,
            description: 'Cabeça',
          },
          {
            correct: true,
            description: 'Pata',
          },
          {
            correct: false,
            description: 'Barriga',
          },
        ],
      },
      {
        question: 'Do que a Masha estava brincando quando colocou o pote na cabeça?',
        answers: [
          {
            correct: true,
            description: 'Astronauta',
          },
          {
            correct: false,
            description: 'Jogador de futebol',
          },
          {
            correct: false,
            description: 'Bombeiro',
          },
        ],
      },
      {
        question: 'Porque o urso voltou para casa triste?',
        answers: [
          {
            correct: false,
            description: 'Estava escuro',
          },
          {
            correct: true,
            description: 'Não encontrou frutas para fazer a geleia',
          },
          {
            correct: false,
            description: 'Porque a Masha ligou para ele',
          },
        ],
      },
      {
        question: 'O que a Masha fez enquanto o urso estava na floresta?',
        answers: [
          {
            correct: true,
            description: 'Preparou vários tipos de geleia',
          },
          {
            correct: false,
            description: 'Ficou brincando com os animais',
          },
          {
            correct: false,
            description: 'Ficou dormindo',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Receita para o Desastre',
    videoUrl: 'https://www.youtube.com/embed/cTUTv7hRqUk',
    quiz: [
      {
        question: 'Qual é a cor do mingau que a Masha preparou?',
        answers: [
          {
            correct: false,
            description: 'Azul ',
          },
          {
            correct: true,
            description: 'Rosa ',
          },
          {
            correct: false,
            description: 'Verde',
          },
        ],
      },
      {
        question: 'Qual jogo o urso estava jogando?',
        answers: [
          {
            correct: false,
            description: 'Futebol',
          },
          {
            correct: true,
            description: 'Damas',
          },
          {
            correct: false,
            description: 'Vídeo game',
          },
        ],
      },
      {
        question: 'Qual animal a Masha alimentou primeiro?',
        answers: [
          {
            correct: true,
            description: 'Coelho',
          },
          {
            correct: false,
            description: 'Gato',
          },
          {
            correct: false,
            description: 'Porco',
          },
        ],
      },
      {
        question: 'Qual alimento o coelho estava colhendo na horta?',
        answers: [
          {
            correct: false,
            description: 'Alface',
          },
          {
            correct: true,
            description: 'Cenoura',
          },
          {
            correct: false,
            description: 'Tomate',
          },
        ],
      },
      {
        question: 'O que a Masha fez com o mingau que ela preparou?',
        answers: [
          {
            correct: true,
            description: 'Deu para os animais da floresta ',
          },
          {
            correct: false,
            description: 'Comeu sozinha',
          },
          {
            correct: false,
            description: 'Jogou fora',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: '&#10024; Abracadabra &#10024;',
    videoUrl: 'https://www.youtube.com/embed/8EYtbauBTU0',
    quiz: [
      {
        question: 'Como estava o tempo nesse dia?',
        answers: [
          {
            correct: true,
            description: 'Chovendo',
          },
          {
            correct: false,
            description: 'Ensolarado',
          },
          {
            correct: false,
            description: 'Nevando',
          },
        ],
      },
      {
        question: 'Com qual objeto a Masha fez uma mágica?',
        answers: [
          {
            correct: false,
            description: 'Carrinho',
          },
          {
            correct: true,
            description: 'Bola',
          },
          {
            correct: false,
            description: 'Boneca',
          },
        ],
      },
      {
        question: 'O que aconteceu com o nariz da Masha?',
        answers: [
          {
            correct: true,
            description: 'Cresceu',
          },
          {
            correct: false,
            description: 'Diminuiu',
          },
          {
            correct: false,
            description: 'Sumiu',
          },
        ],
      },
      {
        question: 'Qual foi o animal que saiu da capa da Masha?',
        answers: [
          {
            correct: false,
            description: 'Elefante',
          },
          {
            correct: false,
            description: 'Pássaro',
          },
          {
            correct: true,
            description: 'Coelho',
          },
        ],
      },
      {
        question: 'Qual é a frase que a Masha usa para fazer as mágicas?',
        answers: [
          {
            correct: true,
            description: 'Abracadabra 123',
          },
          {
            correct: false,
            description: 'Simsalabim',
          },
          {
            correct: false,
            description: 'Alakazam',
          },
        ],
      },
    ],
  },
];

export default data;
