import styled from 'styled-components';
import FriendsImage from '../../../../assets/images/tic-tac-toe/friends.png';

export default styled.img.attrs(() => ({
  alt: 'Friends',
  src: FriendsImage,
}))`
  max-width: 100%;
`;

export const Container = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
`;
