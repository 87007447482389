import styled from 'styled-components';

export const PlayButton = styled.button`
    background: #d8415c;
    border-radius: 25px;
    border: none;
    height: auto;
    width: 220px;
    color: #fff6af;
    line-height: 3.4rem;
    font-size: 2.4rem;
    text-transform: uppercase;
    cursor: pointer;
    transition-duration: 0.4s;
    position: absolute;
    left: 558px;
    top: 380px;
    z-index: 6;
    font-weight: bold;

    &:hover {
        background: #ba2640;
    } 
`;

export const PlayAgainButton = styled.button`
    background: #d8415c;
    border-radius: 25px;
    border: none;
    height: auto;
    width: 400px;
    color: #fff6af;
    line-height: 3.2rem;
    font-size: 2rem;
    text-transform: uppercase;
    cursor: pointer;
    transition-duration: 0.4s;
    position: absolute;
    left: 475px;
    top: 380px;
    z-index: 6;
    font-weight: bold;

    &:hover {
        background: #ba2640;
    } 
`;

export const StyledContainer = styled.div`
    width: 1024px;
    height: 720px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-self: center;
`;

export const Bear = styled.img`
    position: absolute;
    z-index: 5;
    width: 45%;
`;

export const Frame = styled.img`
    top: 100px;
    left: 350px;
    display: block;
    position: absolute;
    z-index: 4;
`;

export const Title = styled.p`
    font-size: 70px;
    font-weight: bold;
    color: #1d1b3e;
    text-align: center;
    z-index: 6;
    line-height: 1;
    top: 230px;
    left: 500px;
    position: absolute;
`;
