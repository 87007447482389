import React from 'react';
import PartnerLink from './PartnerLink';
import {
  LoginFooter, MainLabel, SecondaryLabel, TextContainer,
} from './styles';

import AmazonLogo from '../../../assets/images/login/partners/amazon.png';
import CiatoyLogo from '../../../assets/images/login/partners/ciatoy.png';
import ToniToysLogo from '../../../assets/images/login/partners/toni_toys.png';
import SuperlegalLogo from '../../../assets/images/login/partners/superlegal.png';
import BalaoMagicoLogo from '../../../assets/images/login/partners/balao_magico.png';
import CirandaCulturalLogo from '../../../assets/images/login/partners/ciranda_cultural.png';

export default () => (
  <LoginFooter>
    <TextContainer>
      <MainLabel>
        Garanta seu acesso ao site adquirindo um produto
        Masha e o Urso nas lojas parceiras:
      </MainLabel>
    </TextContainer>

    <div>
      <PartnerLink href="https://www.balaomagicobrinquedos.com.br/" src={BalaoMagicoLogo} />
      <PartnerLink href="https://www.tonitoys.com.br/" src={ToniToysLogo} />
      <PartnerLink href="https://www.superlegalbrinquedos.com.br/" src={SuperlegalLogo} />
      <PartnerLink href="https://www.ciatoy.com.br/" src={CiatoyLogo} />
      <PartnerLink href="https://www.cirandacultural.com.br/vitrine/personagens-masha-e-o-urso" src={CirandaCulturalLogo} />
      <PartnerLink href="https://www.amazon.com.br/stores/page/F5D1FD86-1EDF-484A-8049-75FCD05E0120?ingress=0&visitId=dba98651-4f18-4172-b38a-6e55858d98b" src={AmazonLogo} />
    </div>

    <div>
      <SecondaryLabel>* Amazon - válido somente para produtos Ciranda Cultural</SecondaryLabel>
    </div>
  </LoginFooter>
);
