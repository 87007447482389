import styled from 'styled-components';

export const ButtonMenuContainer = styled.div`
    width: 250px;
    height: 250px;
    background-color: #000;
    display: inline-flex;
    margin: 10px;

    @media (max-width: 767px) {
        width: 100%
        min-width: 200px;
        display: block;
        margin: 20px auto;
    }
`;

export const ImgStyled = styled.img`
    width: 100%;
    display: block;
`;
