import React, { useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

import BearImage from '../../../assets/images/connect-the-dots/bear-dotted.png';

import Container from './Container';
import DrawContainer from './DrawContainer';
import { ButtonsContainer, Button } from './styles';

const Game: React.FC = () => {
  const canvasRef = useRef(null);

  const onDownloadDrawClicked = () => {
    const url = `${process.env.PUBLIC_URL}/healthy-food/ligue-os-pontos.pdf`;
    window.open(url, '_blank');
  };

  const onRestartClicked = () => {
    if (canvasRef) {
      // @ts-ignore
      canvasRef?.current?.clear();
    }
  };

  return (
    <Container>
      <DrawContainer>
        <CanvasDraw
          ref={canvasRef}
          imgSrc={BearImage}
          canvasWidth="930px"
          canvasHeight="1200px"
          brushRadius={2}
          hideGrid
        />

        <ButtonsContainer>
          <Button onClick={() => onDownloadDrawClicked()}>Download</Button>
          <Button onClick={() => onRestartClicked()}>Recomeçar</Button>
        </ButtonsContainer>
      </DrawContainer>
    </Container>
  );
};

export default Game;
