import styled from 'styled-components';
import gridBackground from '../../../assets/images/memory-game/game.png';
import restartButton from '../../../assets/images/memory-game/restart-button.png';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${gridBackground});
    background-repeat: no-repeat;
    background-position: center;
    max-width: 900px;
    width: 850px;
    height: 500px;
    flex-direction: column;
`;

export const RestartButton = styled.div.attrs(() => ({
  alt: 'Restart Button',
  title: 'Reiniciar jogo',
}))`
  background-image: url(${restartButton});
  background-repeat: no-repeat;
  background-position: center; 
  cursor: pointer;
  width: 100px;
  height: 100px;
  transition-duration: 0.4s;
`;

export const WinnerTitle = styled.span`
  font-size: 40px;
  line-height: 35px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: #ffdf88;
  text-shadow: 2px 0 0 #492d65, -2px 0 0 #492d65, 0 2px 0 #492d65, 0 -2px 0 #492d65, 1px 1px #492d65, -1px -1px 0 #492d65, 1px -1px 0 #492d65, -1px 1px 0 #492d65;
`;

export const WinnerSubTitle = styled.span`
font-size: 20px;
line-height: 35px;
font-weight: 900;
text-align: center;
text-transform: uppercase;
color: #422b64;
`;
