import React from 'react';
import DirectionButton from '../DirectionButton';

import {
  DirectionButtonContainer,
  MazeControlsContainer,
  RestartButton,
  RestartButtonContainer,
  StatusLabelContainer,
} from './styles';
import RestartButtonImage from '../../../../assets/images/maze/restart-button.png';
import Direction from '../../../../types/MazeGame/Direction';
import { useAppSelector } from '../../../../store/hooks';
import MazeGameStatusEnum from '../../../../types/MazeGame/MazeGameStatusEnum';

const MazeControls = (props: {
  onDirectionClicked: (direction: Direction) => void,
  onRestartClicked: () => void
}) => {
  const { onDirectionClicked, onRestartClicked } = props;

  const status = useAppSelector((state) => state.maze.status);

  return (
    <MazeControlsContainer className="align-self-end">
      <div className="d-flex flex-row justify-content-center">
        <DirectionButtonContainer className="align-self-center">
          <DirectionButton rotate={180} onClick={() => onDirectionClicked(Direction.LEFT)} />
        </DirectionButtonContainer>

        <DirectionButtonContainer className="align-self-center">
          <DirectionButton rotate={270} onClick={() => onDirectionClicked(Direction.UP)} />
          <DirectionButton rotate={90} onClick={() => onDirectionClicked(Direction.DOWN)} />
        </DirectionButtonContainer>

        <DirectionButtonContainer className="align-self-center">
          <DirectionButton onClick={() => onDirectionClicked(Direction.RIGHT)} />
        </DirectionButtonContainer>
      </div>

      {status === MazeGameStatusEnum.USER_LOST && (
      <StatusLabelContainer>
        <span>Você perdeu :(</span>
      </StatusLabelContainer>
      )}

      {status === MazeGameStatusEnum.USER_WON && (
        <StatusLabelContainer>
          <span>Você ganhou :D</span>
        </StatusLabelContainer>
      )}

      <RestartButtonContainer>
        <RestartButton src={RestartButtonImage} alt="Recomeçar" onClick={onRestartClicked} />
      </RestartButtonContainer>
    </MazeControlsContainer>
  );
};

export default MazeControls;
