import styled from 'styled-components';
import GameContainer from '../../Games/GameContainer';

export const GameContainerStyled = styled(GameContainer)`
    display: flex;  
    height: 100%;  
    min-height: 720px;
    flex: 1;
    align-self: center;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
`;
