import { configureStore } from '@reduxjs/toolkit';

import login from './slices/Login';
import maze from './slices/MazeGame';
import genius from './slices/GeniusGame';

const store = configureStore({
  reducer: {
    maze,
    login,
    genius,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
