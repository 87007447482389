import React from 'react';

import Img from './styles';

export default (
  props: {
    img: string | undefined,
    title: string,
    className?: string | undefined
  },
) => {
  const { img, title, className } = props;

  return (
    <div>
      <Img src={img} alt={title} className={className} />
    </div>
  );
};
