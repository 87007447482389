import styled from 'styled-components';
import MashaImg from '../../../../assets/images/genius-game/masha-start.png';

export const Image = styled.img.attrs(() => ({
  alt: 'Masha',
  src: MashaImg,
}))`
  align-self: center;
  max-width: 465px;
  max-height: 480px;
  
  @media (min-width: 1440px) {
    max-width: 580px;
    max-height: 100%;
  }

  @media (min-width: 1920px) {
    max-width: 620px;
    max-height: 100%;
  }

  @media (min-width: 2048px) {
    max-width: 890px;
    max-height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60%;
  padding-right: 30px;
  padding-top: 4%;
  padding-bottom: 4%;
`;
