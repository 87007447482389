import ApiError from '../types/Common/ApiError';

const errorHandlerMiddleware = async (result: Response) => {
  if (!result.ok) {
    const data = await result.json();
    throw new ApiError(data);
  }
};

const getHeaders = () => {
  const headers: { [key: string]: string; } = {
    'Content-Type': 'application/json',
  };

  const token = localStorage.getItem('token');

  if (token) {
    headers.Authorization = token;
  }

  return headers;
};

export default {
  get: async (route: string) => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
      headers: getHeaders(),
    });
    await errorHandlerMiddleware(result);
    return result.json();
  },
  post: async (route: string, body: any) => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        ...getHeaders(),
      },
    });
    await errorHandlerMiddleware(result);
    return result.json();
  },
};
