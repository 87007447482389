import React from 'react';
import {
  BearContainer, BearImage, MashaContainer, MashaImage, WinnerTitle,
} from '../Player/styles';

interface Props {
  winner: any
}

const Winner: React.FC<Props> = ({ winner }: Props) => (winner === 'masha' ? (
  <MashaContainer>
    <WinnerTitle>Vencedor:</WinnerTitle>
    <MashaImage />
  </MashaContainer>
) : (
  <BearContainer>
    <WinnerTitle>Vencedor:</WinnerTitle>
    <BearImage />
  </BearContainer>
));

export default Winner;
