import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const EndAlignedCol = styled(Col)`
  align-self: flex-end;
  padding-bottom: 2%;
`;

export const EndAlignedRightCol = styled(Col)`
  align-self: flex-end;
  align-items: flex-end;
  padding-bottom: 2%;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const MashaImg = styled.img`
  max-width: 90%;

  @media (min-width: 1025px) {
    max-width: 75%;
  }

  @media (min-width: 1440px) {
    max-width: 320px;
  }
`;

export const AmpImg = styled.img`
  max-width: 90%;

  @media (min-width: 1025px) {
    max-width: 75%;
  }
  
  @media (min-width: 1440px) {
    max-width: 260px;
  }
`;
