import React from 'react';
import StaticPage from '../Common/Containers/StaticPage';
import StaticPageTitle from '../Common/StaticPageTitle';

import ActivitiesTitle from '../../assets/images/activities/title.png';
import StaticPageButton from '../Common/StaticPageButton';
import VerticallyCenteredContainer from '../Common/Containers/VerticallyCenteredContainer';

const Activities: () => JSX.Element = () => (
  <StaticPage>
    <VerticallyCenteredContainer>
      <StaticPageTitle img={ActivitiesTitle} title="Atividades" />

      <StaticPageButton route="/atividades/encontre-o-caminho">Encontre o Caminho</StaticPageButton>
      <StaticPageButton route="/atividades/ligue-os-pontos">Ligue os Pontos</StaticPageButton>
      <StaticPageButton route="/atividades/jogo-da-velha">Jogo da Velha</StaticPageButton>
    </VerticallyCenteredContainer>
  </StaticPage>
);

export default Activities;
