import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button<any>`
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color:  ${(props) => (props.active ? '#ebeaea' : 'black')};
  font-size: 20px;
  background-color: ${(props) => (props.active ? '#60c6ff' : '#ebeaea')};
  margin: 5px;
  margin-bottom: 20px;
  border: none;
`;
