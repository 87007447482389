import React, { useEffect, useState, useCallback } from 'react';
import Row from 'react-bootstrap/Row';

import StaticPage from '../Common/Containers/StaticPage';
import StaticPageTitle from '../Common/StaticPageTitle';

import PartnersTitle from '../../assets/images/partners/title.png';
import api from '../../services/api';
import { Partner } from '../../types/Partners/Partner';
import {
  PartnerCard, PartnerImg, LoadingContainer, CardRow, Container,
} from './styles';

const partnersOrder = [4, 1, 2, 3, 5, 6];

const Partners: () => JSX.Element = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState<Partner[]>([]);

  const [half, setHalf] = useState(0);

  const loadPartners = useCallback(
    async () => {
      setIsLoading(true);

      try {
        const data = await api.get('/partners');

        const typedPartners = data.partners as Partner[];

        const orderedPartners = typedPartners.sort((a, b) => {
          const aIndex = partnersOrder.indexOf(a.id);
          const bIndex = partnersOrder.indexOf(b.id);

          return aIndex - bIndex;
        });

        setHalf(Math.ceil(orderedPartners.length / 2));

        setPartners(orderedPartners);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    loadPartners();
  }, []);

  const title = <StaticPageTitle img={PartnersTitle} title="Parceiros" />;

  return (
    <StaticPage title={title}>
      {isLoading && (
      <LoadingContainer>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      </LoadingContainer>
      )}

      {!isLoading && (
      <Container>
        <Row>
          <CardRow>
            {

            partners.slice(0, half).map((partner) => (
              <PartnerCard key={partner.id}>
                <a href={partner.site} target="_blank" rel="noreferrer">
                  <PartnerImg src={`${process.env.PUBLIC_URL}/partners/${partner.logo}`} />
                </a>
              </PartnerCard>
            ))
          }
          </CardRow>
        </Row>

        <Row>
          <CardRow>
            {
          partners.slice(-half).map((partner) => (
            <PartnerCard key={partner.id}>
              <a href={partner.site} target="_blank" rel="noreferrer">
                <PartnerImg src={`${process.env.PUBLIC_URL}/partners/${partner.logo}`} />
              </a>
            </PartnerCard>
          ))
        }
          </CardRow>
        </Row>
      </Container>
      )}
    </StaticPage>
  );
};

export default Partners;
