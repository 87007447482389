import React from 'react';
import {
  Container, Title, PlayButton, ButtonContainer, Clouds,
} from './styles';

interface Props {
  startGame: () => void
}
const StartGame: React.FC<Props> = ({ startGame }: Props) => (

  <Container>
    <Clouds>
      <Title />
      <ButtonContainer type="button" onClick={startGame}>
        <PlayButton />
      </ButtonContainer>
    </Clouds>
  </Container>

);

export default StartGame;
