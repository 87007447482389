import React, { useState } from 'react';

import GameBackground from '../../assets/images/maze/background.png';
import StartBackground from '../../assets/images/maze/background-start.png';
import StartGame from './StartGame';
import Game from './Game';
import GameScene from '../Games/GameScene';

const MazeGame: () => JSX.Element = () => {
  const [gameStarted, setGameStarted] = useState<boolean>(false);

  const startScreen = <StartGame onStartClick={() => setGameStarted(true)} />;
  const gameScreen = <Game />;

  return (
    <GameScene
      backgroundimage={gameStarted ? GameBackground : StartBackground}
      startScreen={startScreen}
      gameScreen={gameScreen}
      gameStarted={gameStarted}
    />
  );
};

export default MazeGame;
