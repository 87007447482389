import styled from 'styled-components';

export const GameControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10%;
  font-size: 1.8rem;
  color: #FFF;
`;

export const PlayButton = styled.button`
  background: none;
  border: none;
  
  > img {
    max-width: 64px;
  }
`;

export const TurnLabel = styled.span`
  font-size: 3rem;
`;
