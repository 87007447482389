import styled from 'styled-components';
import TitleImage from '../../../assets/images/memory-game/masha.png';

export const TitleContainer = styled.img.attrs(() => ({
  alt: 'Jogo da Memória',
  src: TitleImage,
}))`
 max-width: 978px;
`;

export const PlayButton = styled.button`
  background: #ea3c78;
  border-radius: 35px;
  border: 7px solid #401a64;
  height: 65px;
  width: 205px;
  color: #ffff;
  font-size: 35px;
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background: #ab2f5a
  }
  position: absolute;
  left: 50%;
  top: 55%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
