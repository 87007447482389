import React, { useEffect, useRef, useState } from 'react';

import Canvas from './styles';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import UseCanvasParams from '../../../../types/MazeGame/UseCanvasParams';
import Point from '../../../../types/MazeGame/Point';
import { setLastLineAsDrawn as setLastLineAsDrawnAction } from '../../../../store/slices/MazeGame';

const getVectorFromTwoPoints = (point1: Point, point2: Point) => ({
  x: point2.x - point1.x,
  y: point2.y - point1.y,
});

const getDistanceBetweenPoints = (point1: Point, point2: Point) => {
  const x = point1.x - point2.x;
  const y = point1.y - point2.y;

  return Math.sqrt(x * x + y * y);
};

const MazeCanvas = () => {
  const dispatch = useAppDispatch();
  const path = useAppSelector((state) => state.maze.path);
  const lineWidth = useAppSelector((state) => state.maze.lineWidth);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null);

  const useCanvasContext = (callback: (params: UseCanvasParams) => void): void => {
    const canvas = canvasRef.current;

    if (canvas && canvasContext) {
      callback({ canvas, context: canvasContext });
    }
  };

  const clearCanvas = () => {
    useCanvasContext(({ canvas, context }) => context.clearRect(0, 0, canvas.width, canvas.height));
  };

  // eslint-disable-next-line max-len
  const createAnimationFrame = (context: CanvasRenderingContext2D, startPoint: Point, endPoint: Point) => {
    const getTime = Date.now;

    let rafID: number = 0;
    let isFinished = false;
    let lastUpdate = getTime();
    let currentPoint = startPoint;

    const FRAME_DURATION = 1000 / 60;
    const vector = getVectorFromTwoPoints(startPoint, endPoint);
    const startToEndDistance = getDistanceBetweenPoints(startPoint, endPoint);
    const lineStep = 5 / startToEndDistance;

    const vectorStep = {
      x: vector.x * lineStep,
      y: vector.y * lineStep,
    };

    const animate: () => void = () => {
      const now = getTime();
      const delta = (now - lastUpdate) / FRAME_DURATION;

      const deltaVector = {
        x: vectorStep.x * delta,
        y: vectorStep.y * delta,
      };

      let nextPoint = {
        x: Math.ceil(currentPoint.x + deltaVector.x),
        y: Math.ceil(currentPoint.y + deltaVector.y),
      };

      const startToNextPointDistance = getDistanceBetweenPoints(startPoint, nextPoint);

      if (startToNextPointDistance >= startToEndDistance) {
        isFinished = true;
        nextPoint = endPoint;

        dispatch(setLastLineAsDrawnAction());
      }

      // Draw line segment
      context.beginPath();
      context.moveTo(currentPoint.x, currentPoint.y);
      context.lineTo(nextPoint.x, nextPoint.y);
      context.stroke();

      if (isFinished) {
        cancelAnimationFrame(rafID);
        return;
      }

      currentPoint = nextPoint;
      lastUpdate = now;
      rafID = requestAnimationFrame(animate);
    };

    animate();
  };

  const drawLineAnimated = (startPoint: Point, endPoint: Point) => {
    useCanvasContext(({ context }) => {
      createAnimationFrame(context, startPoint, endPoint);
    });
  };

  const drawLine = (startPoint: Point, endPoint: Point) => {
    useCanvasContext(({ context }) => {
      context.beginPath();
      context.moveTo(startPoint.x, startPoint.y);
      context.lineTo(endPoint.x, endPoint.y);
      context.stroke();
    });
  };

  const drawLines = () => {
    useCanvasContext(({ canvas }) => {
      const baseWidth = canvas.offsetWidth;
      const baseHeight = canvas.offsetHeight;

      path.forEach((line) => {
        const fromX = Math.trunc(line.from.x * baseWidth);
        const fromY = Math.trunc(line.from.y * baseHeight);
        const toX = Math.trunc(line.to.x * baseWidth);
        const toY = Math.trunc(line.to.y * baseHeight);

        const startPoint: Point = {
          x: fromX,
          y: fromY,
        };

        const endPoint: Point = {
          x: toX,
          y: toY,
        };

        if (line.drawn) {
          drawLine(startPoint, endPoint);
        } else {
          drawLineAnimated(startPoint, endPoint);
        }
      });
    });
  };

  const drawAll = () => {
    clearCanvas();
    drawLines();
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const contextRef = (canvas as HTMLCanvasElement).getContext('2d');

    if (!contextRef) {
      return;
    }

    contextRef.strokeStyle = 'white';
    contextRef.lineWidth = lineWidth;

    setCanvasContext(contextRef);
  }, [canvasRef]);

  useEffect(() => {
    drawAll();
  }, [canvasContext]);

  useEffect(() => {
    drawAll();
  }, [path]);

  return <Canvas ref={canvasRef} />;
};

export default MazeCanvas;
