import React, { useEffect } from 'react';
import * as Tone from 'tone';
import ColorButton from './styles';
import Props from './props';

import { useAppSelector } from '../../../../store/hooks';
import GeniusGameStatusEnum from '../../../../types/GeniusGame/GeniusGameStatusEnum';

const NoteButton: (props: Props) => JSX.Element = (props: Props) => {
  const {
    color, position, note, onClick,
  } = props;

  const status = useAppSelector((state) => state.genius.status);
  const noteBeingPlayed = useAppSelector((state) => state.genius.noteBeingPlayed);
  const notes = useAppSelector((state) => state.genius.notes);

  const disabled = status !== GeniusGameStatusEnum.WAITING_USER;

  const active = noteBeingPlayed != null && notes[noteBeingPlayed] === position;

  const onButtonClick = () => {
    const synth = new Tone.Synth().toDestination();
    synth.triggerAttackRelease(note, '8n');

    onClick(position);
  };

  useEffect(() => {
    if (active) {
      const synth = new Tone.Synth().toDestination();
      synth.triggerAttackRelease(note, '8n');
    }
  }, [active]);

  return (
    <ColorButton
      color={color}
      position={position}
      disabled={disabled}
      active={active}
      onClick={() => onButtonClick()}
    />
  );
};

export default NoteButton;
