import styled from 'styled-components';

export const MashaContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-end;
  align-self: center;
`;

export const ControlsContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  margin-bottom: 30px;
`;

export const MashaImage = styled.img``;

export const TitleImage = styled.img`
  max-width: 500px;
`;

export const PlayButton = styled.button`
  align-self: center;
  box-sizing: border-box;
  cursor: pointer;
  width: 205px;
  height: 60px;
  background: #ef7772;
  color: #1d1b3f;
  border: solid 6px #1d1b3f;
  border-radius: 32px;
  font-size: 2.2rem;
  font-weight: 900;
  text-transform: uppercase;
  transition-duration: 0.4s;
  &:hover {
    background: #eb5852;
  }
`;
