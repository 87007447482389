import React from 'react';
import { Container, Button } from './styles';
import { Step } from '../../../types/Challenges/Step';

interface Props {
  steps: Step[];
  currentStep: number;
  setCurrentStep: (newStep: number) => void;
}
const Stepper: React.FC<Props> = ({
  steps,
  currentStep,
  setCurrentStep,
}: Props) => (
  <Container>
    {currentStep !== steps[0].id - 1 && (
    <Button type="button" onClick={() => setCurrentStep(currentStep - 1)} active={false}>
      &lt;
    </Button>
    )}
    {steps.map((step) => (
      <Button type="button" active={step.id - 1 === currentStep} onClick={() => setCurrentStep(step.id - 1)}>
        {step.id}
      </Button>
    ))}
    {currentStep + 1 !== steps[steps.length - 1]?.id && (
    <Button type="button" onClick={() => setCurrentStep(currentStep + 1)} active={false}>
      &gt;
    </Button>
    )}
  </Container>
);

export default Stepper;
