import React from 'react';
import ArrowImage from '../../../../assets/images/maze/arrow-button.png';

import Img from './styles';
import StyledProps from './styled-props';

const DirectionButton = (props: StyledProps) => {
  const { rotate, onClick } = props;

  return (
    <Img src={ArrowImage} alt="Vai" rotate={rotate} onClick={onClick} />
  );
};

export default DirectionButton;
