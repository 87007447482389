import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Link = styled.a`
  cursor: pointer;
  margin-top: 2%;
  padding: 0.8rem 2rem;
  min-width: 440px;
  border: solid 6px white;
  border-radius: 48px;
  box-shadow: rgba(0, 0, 0, 0.30) 0 3px 8px;
  background-image: linear-gradient(#5a1280, #cc007c);
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2rem;
  
  :hover, :active, :focus {
    background: #5a1280;
    color: white;
  }
`;
