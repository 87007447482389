import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Button = styled.button`
  padding: 0.8rem 1.6rem;
  margin: 0 2rem; 
  background: #fcc839;
  border-radius: 28px;
  border: none;
  color: #1d1b3f;
  line-height: 1.5rem;
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background: #d49c2c
  }
`;
