import React, { useState } from 'react';
import GameOverModal from './GameOverModal';
import {
  Stumb, Masha, MashaGame, Stone, Ground, Clouds, Trees,
} from './styles';

const Game: React.FC = () => {
  const [mashaJump, setMashaJump] = useState<boolean>(false);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [enableObstacles, setEnableObstacles] = useState<boolean>(false);
  const [gameScore, setGameScore] = useState<number>(0);

  const forceRender = React.useCallback(() => {
    setMashaJump(false);
    setGameOver(false);
    setEnableObstacles(false);
    setGameScore(0);
    setTimeout(() => {
      setEnableObstacles(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    const jump = () => {
      setMashaJump(true);

      setTimeout(() => {
        setMashaJump(false);
      }, 800);
    };

    const isAlive = () => setInterval(() => {
      const masha = document.getElementById('masha');
      const stumb = document.getElementById('stumb');
      const stone = document.getElementById('stone');
      if (masha && stumb && stone) {
        const mashaBottom = parseInt(window.getComputedStyle(masha).getPropertyValue('bottom'), 10);

        const stumbLeft = parseInt(
          window.getComputedStyle(stumb).getPropertyValue('left'),
          10,
        );

        const stoneLeft = parseInt(
          window.getComputedStyle(stone).getPropertyValue('left'),
          10,
        );

        if ((stumbLeft > 0 && stumbLeft < 50) && mashaBottom < 200) {
          setGameOver(true);
        }

        if ((stoneLeft > 0 && stoneLeft < 50) && mashaBottom < 200) {
          setGameOver(true);
        }
      }
    }, 10);

    isAlive();
    document.addEventListener('keydown', (event) => {
      event.preventDefault();
      if (event.keyCode === 32) {
        jump();
      }
    });
    setTimeout(() => {
      setEnableObstacles(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    const clouds = document.getElementById('clouds');
    const masha = document.getElementById('masha');
    const stumb = document.getElementById('stumb');
    const stone = document.getElementById('stone');
    const ground = document.getElementById('ground');
    const trees = document.getElementById('trees');
    if (gameOver === true) {
      if (clouds) { clouds.style.animationPlayState = 'paused'; }
      if (masha) { masha.style.animationPlayState = 'paused'; }
      if (stumb) { stumb.style.animationPlayState = 'paused'; }
      if (stone) { stone.style.animationPlayState = 'paused'; }
      if (ground) { ground.style.animationPlayState = 'paused'; }
      if (trees) { trees.style.animationPlayState = 'paused'; }
    } else {
      if (clouds) { clouds.style.animationPlayState = 'running'; }
      if (masha) { masha.style.animationPlayState = 'running'; }
      if (stumb) { stumb.style.animationPlayState = 'running'; }
      if (stone) { stone.style.animationPlayState = 'running'; }
      if (ground) { ground.style.animationPlayState = 'running'; }
      if (trees) { trees.style.animationPlayState = 'running'; }
    }
  }, [gameOver]);

  React.useEffect(() => {
    const stumb = document.getElementById('stumb');
    const stone = document.getElementById('stone');
    if (mashaJump === true && (stumb && stone)) {
      const stumbLeft = parseInt(
        window.getComputedStyle(stumb).getPropertyValue('left'),
        10,
      );
      const stoneLeft = parseInt(
        window.getComputedStyle(stone).getPropertyValue('left'),
        10,
      );
      if ((stumbLeft > 0 && stumbLeft < 260)) {
        setGameScore(gameScore + 1);
      }
      if ((stoneLeft > 0 && stoneLeft < 260)) {
        setGameScore(gameScore + 1);
      }
    }
  }, [mashaJump, gameScore]);

  return (
    <MashaGame>
      <Clouds id="clouds" gameOver={gameOver} />
      <Masha id="masha" jump={mashaJump} gameOver={gameOver} />
      {enableObstacles && (
        <>
          <Stumb id="stumb" gameOver={gameOver} />
          <Stone id="stone" gameOver={gameOver} />
        </>
      )}
      <Ground id="ground" gameOver={gameOver} />
      <Trees id="trees" gameOver={gameOver} />
      <GameOverModal
        active={gameOver}
        restartGame={async () => forceRender()}
      />
    </MashaGame>

  );
};

export default Game;
