import React from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import NavbarBrand from '../NavbarBrand';

const LoginNavbar: React.FC = () => (
  <Navbar bg="light">
    <Container>
      <Nav className="m-auto">
        <NavbarBrand className="mx-auto" />
      </Nav>
    </Container>
  </Navbar>
);

export default LoginNavbar;
