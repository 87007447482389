import React from 'react';

import ImageContainer, { Container } from './styles';

const BearContainer: React.FC = () => (
  <Container>
    <ImageContainer />
  </Container>
);

export default BearContainer;
