import styled from 'styled-components';

import { Container } from 'react-bootstrap';
import DetailImage from '../../../../assets/images/genius-game/detail.png';

export default styled(Container).attrs({
  fluid: true,
})`
  background-image: url('${DetailImage}');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto 50%;

  @media (min-width: 1441px) {
    width: 100%;
  }
`;
