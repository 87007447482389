import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import BootstrapContainer from 'react-bootstrap/Container';
import Smartphone1 from '../../assets/images/challenges/smartphone.png';
import Smartphone2 from '../../assets/images/challenges/smartphone-2.png';
import Masha from '../../assets/images/challenges/masha.png';
import Masha1 from '../../assets/images/challenges/card-masha-1.png';
import Masha2 from '../../assets/images/challenges/card-masha-2.png';
import Masha3 from '../../assets/images/challenges/card-masha-3.png';
import Masha4 from '../../assets/images/challenges/card-masha-4.png';

export const ContentContainer = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column; ;
`;

export const Container = styled(BootstrapContainer)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Subtitle = styled.span`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: #353839;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
`;

export const ChallengeContent = styled.span`
  font-size: 1rem;
  line-height: 1.6rem;
  color: #353839;
`;

export const ChallengeTitle = styled.span`
  font-size: 1.8rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: #ff3702;
`;

export const SmartPhoneImage = styled.img.attrs({
  alt: 'Smartphone 1',
  src: Smartphone1,
})`
  margin: 30px;
`;

export const SmartPhoneImage2 = styled.img.attrs({
  alt: 'Smartphone 2',
  src: Smartphone2,
})`
  margin: 30px;
`;

export const MashaImage = styled.img.attrs({
  alt: 'Masha',
  src: Masha,
})`
  margin: 20px;
`;

export const MashaImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CardMasha1 = styled.img.attrs({
  alt: 'Card Masha 1',
  src: Masha1,
})`
  padding: 10px;
`;

export const CardMasha2 = styled.img.attrs({
  alt: 'Card Masha 2',
  src: Masha2,
})`
  padding: 10px;
`;
export const CardMasha3 = styled.img.attrs({
  alt: 'Card Masha 3',
  src: Masha3,
})`
  padding: 10px;
`;
export const CardMasha4 = styled.img.attrs({
  alt: 'Card Masha 4',
  src: Masha4,
})`
  padding: 10px;
`;
