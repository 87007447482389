import React from 'react';
import StaticPage from '../Common/Containers/StaticPage';
import StaticPageTitle from '../Common/StaticPageTitle';

import EpisodesTitle from '../../assets/images/episodes/title.png';

import buildRoute from '../../services/route-builder';

import ButtonEpisodes from './styles';

const Episodes: () => JSX.Element = () => {
  const title = <StaticPageTitle className="mb-5" img={EpisodesTitle} title="Episódios" />;
  const query = window.location.search;

  return (
    <StaticPage title={title}>
      <ButtonEpisodes href={buildRoute('/episodios/1', query)}>&#129336; DESAFIO DE DANÇA &#128131;</ButtonEpisodes>
      <ButtonEpisodes href={buildRoute('/episodios/2', query)}>DIA DA GELEIA &#127826;</ButtonEpisodes>
      <ButtonEpisodes href={buildRoute('/episodios/3', query)}>RECEITA PARA O DESASTRE</ButtonEpisodes>
      <ButtonEpisodes href={buildRoute('/episodios/4', query)}>&#10024; ABRACADABRA &#10024;</ButtonEpisodes>
    </StaticPage>
  );
};

export default Episodes;
