import React from 'react';
import { Img } from './styles';

export default (props: { src: string, href: string }) => {
  const { src, href } = props;

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Img src={src} />
    </a>
  );
};
