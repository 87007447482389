import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../Footer';

import {
  InputCPF, Form, FormControl, BtnLogin, Feedback,
} from './styles';

import api from '../../../services/api';
import Signup from '../../../types/Login/Signup';
import { useAppDispatch } from '../../../store/hooks';
import { setToken as setTokenAction } from '../../../store/slices/Login';

const FormLogin = (props: RouteComponentProps) => {
  const { location } = props;
  const query = location.search;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [partner, setPartner] = useState<Signup>(process.env.NODE_ENV === 'development' ? {
    name: 'Masha',
    document: '907.893.050-03',
    productSku: 'BR977',
  } : {
    name: '',
    document: '',
    productSku: '',
  });

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setIsLoading(true);

      try {
        const data = await api.post('/users/signup', partner);

        localStorage.setItem('token', data.token);
        dispatch(setTokenAction(data.token));

        history.push(`/${query}`);
      } catch (err: any) {
        setError(true);
        setErrorMessage(err.message);

        setTimeout(() => {
          setError(false);
        }, 5000);
      } finally {
        setIsLoading(false);
      }
    }

    setValidated(true);
  };

  const handleChange = (e:any) => {
    const { name, value } = e.target;

    setPartner((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" className="text-center" controlId="txtNome">
            <FormControl
              required
              type="text"
              name="name"
              placeholder="NOME"
              autoComplete="off"
              onChange={handleChange}
              disabled={isLoading}
              value={partner.name}
            />

            <Feedback type="invalid">Preencha este campo</Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" className="text-center" controlId="txtCPF">
            <InputCPF
              required
              type="text"
              placeholder="CPF"
              className="form-control"
              name="document"
              mask="999.999.999-99"
              autoComplete="off"
              onChange={handleChange}
              disabled={isLoading}
              value={partner.document}
            />
            <Feedback type="invalid">Preencha este campo</Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" className="text-center" controlId="txtSKU">
            <FormControl
              required
              type="text"
              name="productSku"
              placeholder="CÓDIGO DE BARRAS DO PRODUTO"
              autoComplete="off"
              onChange={handleChange}
              disabled={isLoading}
              value={partner.productSku}
            />
            <Feedback type="invalid">Preencha este campo</Feedback>
          </Form.Group>
        </Row>

        { error && <p className="text-white"><small>{errorMessage}</small></p> }
        <BtnLogin type="submit" className="btn" disabled={isLoading}>ENTRAR</BtnLogin>
      </Form>
      <Footer />
    </>
  );
};

export default FormLogin;
