import styled, { css, keyframes } from 'styled-components';
import MashaImg from '../../../assets/images/super-masha/masha-running.png';
import MashaImg2 from '../../../assets/images/super-masha/masha.png';
import StumbImg from '../../../assets/images/super-masha/stump.png';
import StoneImg from '../../../assets/images/super-masha/stone.png';
import GroundImg from '../../../assets/images/super-masha/ground.png';
import CloudsImg from '../../../assets/images/super-masha/clouds-moving.png';
import TreesImg from '../../../assets/images/super-masha/trees.png';

export const MashaGame = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: linear-gradient(#2281E2, #5BD0ED);
  background-color: #5acfed;
`;

export const jump = keyframes`
  0% {
    bottom: 100px;
  }
  50% {
    bottom: 360px;
  }
  100% {
    bottom: 100px;
  }
`;

export const Jump = css`
  animation: ${jump} 0.8s;
  animation-direction: alternate;
`;

const walk = keyframes`
  0%{
    background-image: url(${MashaImg});
  }
  100%{
    background-image: url(${MashaImg2});
  }
 `;

export const Walk = css`
  animation: ${walk} 0.5s infinite;
`;

export const Masha = styled.div.attrs((props: { jump: boolean, gameOver: boolean }) => props)`
  width: 100px;
  height: 120px;
  background-image: url(${MashaImg});
  background-size: 100px 120px;
  position: absolute;
  bottom: 100px;
  left: 100px;
  z-index: 100;
  ${(props) => (props.jump === true ? Jump : Walk)};
`;

export const blockStumb = keyframes`
  from { left: 100%; }
  to { left: -500px; }
`;

export const blockStone = keyframes`
  from { left: 100%; }
  to { left: -1200px; }
`;

export const Stumb = styled.div.attrs((props: { gameOver: boolean }) => props)`
  width: 120px;
  height: 70px;
  position: absolute;
  bottom: 100px;
  background-image: url(${StumbImg});
  background-size: 120px 70px;
  z-index: 120;
  animation: ${blockStumb} 3s infinite linear;
`;

export const Stone = styled.div.attrs((props: { gameOver: boolean }) => props)`
  width: 95px;
  height: 40px;
  position: absolute;
  bottom: 100px;
  left: 150px;
  background-image: url(${StoneImg});
  background-size: 95px 40px;
  z-index: 120;
  animation: ${blockStone} 4s infinite linear;
`;

const animatedGround = keyframes`
  from { background-position: 0 0; }
  to { background-position: -1024px 0; }
`;

export const Ground = styled.div.attrs((props: { gameOver: boolean }) => props)`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index:10;
  background-image: url(${GroundImg});
  background-repeat: repeat;
  animation: ${animatedGround} 3s linear infinite;
`;

const animatedTree = keyframes`
  from { background-position: 0 0; }
  to { background-position: -1020px 0; }
`;

export const Trees = styled.div.attrs((props: { gameOver: boolean }) => props)`
  position: absolute;
  bottom: 90px;
  width: 100%;
  height: 121px;
  z-index: 0;
  background-image: url(${TreesImg});
  background-repeat: repeat;
  background-attachment: scroll;
  animation: ${animatedTree} 3s linear infinite;
`;

const animatedClouds = keyframes`
  from { background-position: 0 0; }
  to { background-position: -929px 0; }
`;

export const Clouds = styled.div.attrs((props: { gameOver: boolean }) => props)`
  background-image: url(${CloudsImg});
  margin: 30px;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  position: absolute;
  height: 250px;
  background-position: 0 0;
  background-repeat: repeat-x;
  animation: ${animatedClouds} 16s linear infinite;
`;
