import React from 'react';
import { RouteComponentProps } from 'react-router';

import ButtonMenu from './ButtonMenu';

import DesafiosImg from '../../assets/images/home/challenges.png';
import AtividadesImg from '../../assets/images/home/activities.png';
import JogosImg from '../../assets/images/home/games.png';
import OficinasImg from '../../assets/images/home/workshops.png';
import EpisodiosImg from '../../assets/images/home/episodes.png';
import ParceirosImg from '../../assets/images/home/partners.png';

import ContainerMenu from './styles';
import buildRoute from '../../services/route-builder';

const Home: (props: RouteComponentProps) => JSX.Element = (props: RouteComponentProps) => {
  const { location } = props;
  const query = location.search;

  return (
    <ContainerMenu>
      <a href={buildRoute('/desafios', query)}><ButtonMenu alt="Desafios" src={DesafiosImg} /></a>
      <a href={buildRoute('/atividades', query)}><ButtonMenu alt="Atividades" src={AtividadesImg} /></a>
      <a href={buildRoute('/jogos', query)}><ButtonMenu alt="Jogos" src={JogosImg} /></a>

      <a href={buildRoute('/oficinas', query)}><ButtonMenu alt="Oficinas" src={OficinasImg} /></a>
      <a href={buildRoute('/episodios', query)}><ButtonMenu alt="Episódios" src={EpisodiosImg} /></a>
      <a href={buildRoute('/parceiros', query)}><ButtonMenu alt="Parceiros" src={ParceirosImg} /></a>
    </ContainerMenu>
  );
};

export default Home;
