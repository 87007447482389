/* eslint-disable max-len */
import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';

export default function LoginRoute({ ...routeProps }: RouteProps) {
  const token = useAppSelector((state) => state.login.token);
  const isAuthenticated = !!token;

  if (!isAuthenticated) {
    return <Route {...routeProps} />;
  }

  return <Redirect to="/" />;
}
