import React from 'react';

import buildRoute from '../../../services/route-builder';
import Props from './props';
import { Link } from './styles';

const StaticPageButton: (props: Props) => JSX.Element = (props: Props) => {
  const { route, children } = props;
  const query = window.location.search;

  return <Link href={buildRoute(route, query)}>{children}</Link>;
};

export default StaticPageButton;
