import * as Phaser from 'phaser';

const ground = `${process.env.PUBLIC_URL}/healthy-food/ground.png`;
const apple = `${process.env.PUBLIC_URL}/healthy-food/fruit-1.png`;
const greenGrape = `${process.env.PUBLIC_URL}/healthy-food/fruit-2.png`;
const banana = `${process.env.PUBLIC_URL}/healthy-food/fruit-3.png`;
const pineapple = `${process.env.PUBLIC_URL}/healthy-food/fruit-4.png`;
const pear = `${process.env.PUBLIC_URL}/healthy-food/fruit-5.png`;
const orange = `${process.env.PUBLIC_URL}/healthy-food/fruit-6.png`;
const redGrape = `${process.env.PUBLIC_URL}/healthy-food/fruit-7.png`;
const strawberry = `${process.env.PUBLIC_URL}/healthy-food/fruit-8.png`;

const cesta = `${process.env.PUBLIC_URL}/healthy-food/basket.png`;
const cestaTop = `${process.env.PUBLIC_URL}/healthy-food/basket-top.png`;
const cestaBottom = `${process.env.PUBLIC_URL}/healthy-food/basket-bottom.png`;

export default class MainScene extends Phaser.Scene {
  public fruitPositions = [100, 150, 200, 250, 300, 350, 600, 650, 700, 750, 800, 850, 900, 950];

  public onGame: boolean = true;

  public lblTimer?: Phaser.GameObjects.Text;

  public score: number = 0;

  public timer: number = 0;

  public contador: number = 0;

  public timerLoop?: Phaser.Time.TimerEvent;

  public lblScore?: Phaser.GameObjects.Text;

  public fruitVetor: string[] = ['apple', 'greenGrape', 'redGrape', 'banana', 'pineapple', 'pear', 'orange', 'strawberry'];

  public spriteCestaTop?: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;

  public spriteCestaBottom?: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;

  public sprtGround?: Phaser.GameObjects.Sprite;

  public onGameOver: Function;

  public naoColetadas: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody[] = [];

  constructor(onGameOver: Function) {
    super('MainScene');
    this.onGameOver = onGameOver;
  }

  init() {
    this.onGame = true;
    this.contador = 30;
    this.score = 0;
  }

  preload() {
    this.load.image('ground', ground);
    this.load.spritesheet('apple', apple, { frameWidth: 62, frameHeight: 77 });
    this.load.spritesheet('greenGrape', greenGrape, { frameWidth: 64, frameHeight: 91 });
    this.load.spritesheet('banana', banana, { frameWidth: 66, frameHeight: 62 });
    this.load.spritesheet('pineapple', pineapple, { frameWidth: 61, frameHeight: 122 });
    this.load.spritesheet('pear', pear, { frameWidth: 37, frameHeight: 56 });
    this.load.spritesheet('orange', orange, { frameWidth: 58, frameHeight: 66 });
    this.load.spritesheet('redGrape', redGrape, { frameWidth: 68, frameHeight: 97 });
    this.load.spritesheet('strawberry', strawberry, { frameWidth: 44, frameHeight: 48 });
    this.load.spritesheet('cesta', cesta, { frameWidth: 113, frameHeight: 133 });
    this.load.spritesheet('cesta-top', cestaTop, { frameWidth: 113, frameHeight: 62 });
    this.load.spritesheet('cesta-bottom', cestaBottom, { frameWidth: 113, frameHeight: 85 });
  }

  create() {
    this.onGame = true;

    this.sprtGround = this.physics.add.staticSprite(0, 610, 'ground').refreshBody();
    this.sprtGround.setOrigin(0, 0);
    this.sprtGround.setDepth(1);

    this.spriteCestaTop = this.physics.add.sprite(0, 520, 'cesta-top');
    this.spriteCestaTop.setOrigin(0, 0);
    this.spriteCestaTop.setDepth(2);
    this.spriteCestaTop.setCollideWorldBounds(true);

    this.spriteCestaBottom = this.physics.add.sprite(0, 569, 'cesta-bottom');
    this.spriteCestaBottom.setOrigin(0, 0);
    this.spriteCestaBottom.setDepth(4);
    this.spriteCestaBottom.setCollideWorldBounds(true);

    this.physics.add.collider(this.spriteCestaBottom, this.sprtGround);

    this.lblScore = this.add.text(10, 10, `FRUTAS COLETADAS: ${this.score}`, {
      color: '#000',
      fontSize: '32px',
      fontFamily: 'BubbleboddyNeue',
    });
    this.lblScore.setOrigin(0, 0);
    this.lblScore.setDepth(5);

    this.lblTimer = this.add.text(800, 10, `TEMPO: ${this.score}`, {
      color: '#000',
      fontSize: '32px',
      fontFamily: 'BubbleboddyNeue',
    });
    this.lblTimer.setOrigin(0, 0);
    this.lblTimer.setDepth(5);

    this.timerLoop = this.time.addEvent({
      delay: 500,
      loop: true,
      callback: this.dropFruit,
      startAt: 499,
      callbackScope: this,
    });

    this.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => {
        this.contador -= 1;
      },
      callbackScope: this,
    });
  }

  dropFruit() {
    const randonX = this.fruitPositions[Math.floor(Math.floor(Math.random() * 14))];
    const fruitSelect = this.fruitVetor[Math.floor(Math.random() * this.fruitVetor.length)];
    const fruit = this.physics.add.sprite(randonX, -90, fruitSelect);

    fruit.setGravityY(150);
    fruit.setBounceY(0.4);
    fruit.setOrigin(0, 0);
    fruit.setDepth(3);

    if (this.spriteCestaBottom) {
      this.physics.add.overlap(this.spriteCestaBottom, fruit, this.collectFruit, undefined, this);
    }
  }

  update() {
    const pointer = this.input.activePointer;

    if (this.spriteCestaTop && pointer.worldX < (1024 - this.spriteCestaTop.width)) {
      this.spriteCestaTop?.setX(pointer.worldX);
      this.spriteCestaBottom?.setX(pointer.worldX);
    }

    if (this.contador === 0) {
      this.onGame = false;
      this.onGameOver(this.score);
    }

    if (this.contador <= 10) {
      this.lblTimer?.setColor('#F00');
      this.lblTimer?.setFontSize(34);
    }

    this.lblTimer?.setText(`TEMPO: ${this.contador}`);
  }

  collectFruit(basket: any, fruit: any) {
    const basketX = basket.x + basket.width;
    const fruitWidthX = fruit.x + fruit.width;
    const fruitHeightY = fruit.y + (fruit.height / 2);

    if (this.naoColetadas.indexOf(fruit) < 0) {
      if (fruit.x >= basket.x && fruitWidthX <= basketX) {
        if (fruitHeightY >= basket.y) {
          this.score += 1;
          fruit.disableBody(true, true);
          this.lblScore?.setText(`FRUTAS COLETADAS: ${this.score}`);
        }
      } else {
        fruit.setDepth(5);
        this.naoColetadas.push(fruit);
      }
    }
  }

  startGame() {
    this.fruitVetor = ['apple', 'greenGrape', 'redGrape', 'banana', 'pineapple', 'pear', 'orange', 'strawberry'];
  }
}
