import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import TicTacToe from '../../components/TicTacToe';

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (): {} => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const TicTacToePage: (props: Props) => JSX.Element = (/* props: Props */) => (
  <TicTacToe />
);

export default connector(TicTacToePage);
