import React from 'react';
import StaticPage from '../Common/Containers/StaticPage';
import StaticPageTitle from '../Common/StaticPageTitle';

import Subtitle from './styles';

import WorkshopsTitle from '../../assets/images/workshops/title.png';

import Video from './Video';

const Workshops: () => JSX.Element = () => {
  const title = <StaticPageTitle img={WorkshopsTitle} title="Oficinas" />;

  return (
    <StaticPage title={title}>
      <p className="my-4">
        O que acha de aprender a fazer uma geleia bem gostosa?
        E fabricar sua própria massinha ou instrumentos musicais
        utilizando ingredientes que você tem em casa?
        Assista aos vídeos e boa diversão!
      </p>

      <Subtitle>OFICINA DE MASSINHA</Subtitle>

      <Video src="/oficinas/massinha.mp4" />

      <Subtitle>OFICINA DE GELEIA</Subtitle>

      <Video src="/oficinas/geleia.mp4" />

      <Subtitle>OFICINA DE INSTRUMENTOS MUSICAIS</Subtitle>

      <Video src="/oficinas/instrumentos-musicais.mp4" />
    </StaticPage>
  );
};

export default Workshops;
