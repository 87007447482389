import styled from 'styled-components';

import Maze from '../../../../assets/images/maze/maze.png';

export default styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  width: 600px;
  height: 415px;
  background-image: url(${Maze});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media (min-width: 1440px) {
    width: 888px;
    height: 615px;
  }
`;
