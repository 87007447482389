import React from 'react';

import {
  MashaContainer, BearContainer, MashaImage, BearImage, WhoPlaysTitle,
} from './styles';

interface Props {
  whoPlays: string
}

const Player: React.FC<Props> = ({ whoPlays }: Props) => (whoPlays === 'masha' ? (
  <MashaContainer>
    <WhoPlaysTitle>Quem joga:</WhoPlaysTitle>
    <MashaImage />
  </MashaContainer>
) : (
  <BearContainer>
    <WhoPlaysTitle>Quem joga:</WhoPlaysTitle>
    <BearImage />
  </BearContainer>
));

export default Player;
