import React from 'react';

import Navbar from '../../Navbar';
import PageContainer from '../PageContainer';
import Container from '../../../../styles';
import ModalLGPD from '../../ModalLGPD';
import Router from '../../../../router';
import Footer from '../../Footer';
import { useAppSelector } from '../../../../store/hooks';

const RootComponent: () => JSX.Element = () => {
  const token = useAppSelector((state) => state.login.token);
  const isLogged = !!token;

  return (
    <div id="main-container" className={isLogged ? 'd-flex flex-column' : 'login d-flex flex-column'}>
      <Navbar />
      <PageContainer>
        <Container fluid>
          <ModalLGPD />
          <Router />
        </Container>
      </PageContainer>
      <Footer />
    </div>
  );
};

export default RootComponent;
