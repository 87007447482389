export default class ApiError extends Error {
  private data: object;

  constructor(data: { error: { type: string, description: string } }) {
    super(data.error.description);
    this.name = 'AppError';
    this.data = data;
  }

  getData(): object {
    return this.data;
  }
}
