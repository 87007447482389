import styled from 'styled-components';

export const Subtitle = styled.span`
    font-size: 1.5rem;
    color: #e4161b;
    text-transform: uppercase;
    font-weight: bold;
    margin: .5rem 0;
`;

export const VideoYT = styled.iframe`
    width: 100%;
    max-width: 854px;
    height: 480px;
    margin-bottom: 1rem;
`;
