import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import MazeGame from '../../components/Maze';

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (): {} => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Maze: (props: Props) => JSX.Element = () => (<MazeGame />);

type Props = ConnectedProps<typeof connector>;

export default connector(Maze);
