import React from 'react';

import {
  BackgroundContainer, ContentContainer, Container,
} from './styles';

export default (props: {
  title?: React.ReactElement, children?: React.ReactElement | React.ReactElement[] | React.ReactNode
}) => {
  const {
    title, children,
  } = props;

  return (
    <BackgroundContainer>
      <Container>
        <ContentContainer>
          {title}
          {children}
        </ContentContainer>
      </Container>
    </BackgroundContainer>
  );
};
