import React from 'react';

import TitleImg from '../../assets/images/treasure-hunt/title.png';
import { Subtitle } from './styles';
import ButtonBaixeAsPistas from './ButtonBaixeAsPistas';
import StaticPage from '../Common/Containers/StaticPage';

const TreasureHunt = () => {
  const title = <img className="mb-2" src={TitleImg} alt="Caça ao Tesouro" />;

  return (
    <StaticPage title={title}>
      <Subtitle>OLÁ RESPONSÁVEL!</Subtitle>

      <p className="mb-4">
        Brincar de caça ao tesouro é diversão na certa.
        Desvendar pistas pela casa, trabalhar em grupo e procurar pelo prêmio é tudo de bom!
        Siga os passos a seguir e boa diversão.
      </p>

      <Subtitle>PASSO 1 - DEFINA O PRÊMIO</Subtitle>

      <p className="mb-4">
        A brincadeira não é nada sem um tesouro!
        Este prêmio precisa ser pensado com muito carinho.
        Ele é o objetivo de todo o jogo.
        Faça uma caixa bonita, bem enfeitada,
        para a descoberta ser surpreendente!
        <br />
        A recompensa pode ser um kit de lembrancinhas, um brinquedo
        ou até mesmo uma comidinha bem gostosa.
      </p>

      <Subtitle>PASSO 02 - PISTAS</Subtitle>

      <p className="mb-4">
        Preparamos para você uma sugestão de pistas.
        Basta imprimir, recortar e dobrar.
        <br />
        Para a brincadeira ser ainda mais desafiadora e personalizada,
        você pode acrescentar novas, que se encaixe no dia a dia da(s) criança(s).
      </p>

      <Subtitle>PASSO 03 - ESCONDA AS PISTAS</Subtitle>

      <p className="mb-4">
        Com os papeizinhos já cortados e dobrados,
        esconda as pistas nos locais.
        Ah, não esqueça onde você escondeu as pistas.
      </p>

      <ButtonBaixeAsPistas />
    </StaticPage>
  );
};

export default TreasureHunt;
