import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import GeniusGame from '../../components/GeniusGame';

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (): {} => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const GeniusGamePage: (props: Props) => JSX.Element = () => (<GeniusGame />);

export default connector(GeniusGamePage);
