import React, { useState } from 'react';
import Game from './Game';
import StartGame from './StartGame';
import GameContainer from './styles';
import GameScene from '../Games/GameScene';

const SuperMasha: React.FC = () => {
  const [gameStarted, setGameStarted] = useState<boolean>(false);

  const startScreen = (
    <GameContainer>
      <StartGame startGame={() => setGameStarted(true)} />
    </GameContainer>
  );

  const gameScreen = <Game />;

  return (
    <GameScene
      startScreen={startScreen}
      gameScreen={gameScreen}
      gameStarted={gameStarted}
      fullWidth
      fullHeight
    />
  );
};

export default SuperMasha;
