import styled from 'styled-components';
import TitleImage from '../../../assets/images/connect-the-dots/title.png';

export const TitleImg = styled.img.attrs(() => ({
  alt: 'Ligue os Pontos',
  src: TitleImage,
}))`
  max-width: 100%;
`;

export const PlayButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  padding: 4px;
  
  background: #fccb1c;
  border-radius: 25px;
  border: none;
  height: 50px;
  width: 170px;
  color: #1f163c;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background: #d49c2c
  }
`;

export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
`;

export const TitleContainer = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  @media (max-width: 1024px) {
    margin-right: 12%;
  }
`;
