import styled from 'styled-components';
import TitleImage from '../../../assets/images/genius-game/title.png';

export const TitleImg = styled.img.attrs(() => ({
  alt: 'Jogo da Memória Musical',
  src: TitleImage,
}))`
  max-width: 360px;
  margin-bottom: 30px;

  @media (min-width: 1440px) {
    max-width: 400px;
  }

  @media (min-width: 1920px) {
    max-width: 450px;
  }

  @media (min-width: 2048px) {
    max-width: 100%;
  }
`;

export const PlayButton = styled.button`
  align-self: center;
  background: #5dc2eb;
  border-radius: 25px;
  border: none;
  height: 50px;
  width: 170px;
  color: #000000;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  transition-duration: 0.4s;
  padding: 4px;
  margin-left: 10px;
  &:hover {
    background: #178dbd;
  }
`;

export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4%;
  padding-bottom: 4%;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-right: 12%;
  }
`;
