import styled from 'styled-components';

export default styled.a`
    width: 100%;
    max-width: 370px;
    min-height: 35px;
    line-height: 38px;
    color: #FFF;
    border: 0;
    outline: none;
    padding: 0 1.5rem;
    font-size: 1.3rem;
    text-decoration: none;
    border-radius: 20px;
    background-color: #de138c; 
    margin: .5rem auto;
    font-weight: bold;
    transition: .1s all;


    :hover {
        background-color: #fe23a5;
        color: #FFF;
    }
`;
