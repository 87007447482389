import styled from 'styled-components';

import ReactForm from 'react-bootstrap/Form';

import InputMask from 'react-input-mask';

const styledInput = (elem:any) => styled(elem)`
    width: 100%;
    height: 45px;
    max-width: 450px;
    border-radius: 20px;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
    margin: 0 auto;
    box-shadow: 0 3px 5px rgba(0,0,0,.3);
`;

export const InputCPF = styledInput(InputMask);

export const FormControl = styledInput(ReactForm.Control);

export const BtnLogin = styled.button`
    width: 170px;
    height: 45px;
    color: #FFF;
    background-color: #ffc100;
    border-radius: 15px;
    font-size: 25px;
    font-weight: bold;
    font-family: "BubbleboddyNeue";
    box-shadow: 0 3px 5px rgba(0,0,0,.3);
`;

export const Feedback = styled(ReactForm.Control.Feedback)`
    color: #FFF;
`;

export const Form = styled(ReactForm)`
  margin-bottom: 32px;
`;
