import React from 'react';
import { Provider } from 'react-redux';

import store from '../../../../store';
import MainContainer from '../MainContainer';

const RootComponent: () => JSX.Element = () => (
  <Provider store={store}>
    <MainContainer />
  </Provider>
);

export default RootComponent;
