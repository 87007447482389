import React, { useState } from 'react';

import PageTitle from '../../assets/images/challenges/title.png';
import StaticPageTitle from '../Common/StaticPageTitle';
import StaticPage from '../Common/Containers/StaticPage';
import Stepper from './Stepper';

import {
  ChallengeTitle,
  Container,
  ContentContainer,
  ChallengeContent,
  Subtitle,
  SmartPhoneImage,
  SmartPhoneImage2,
  MashaImage,
  MashaImages,
  CardMasha1,
  CardMasha2,
  CardMasha3,
  CardMasha4,
} from './styles';
import { Step } from '../../types/Challenges/Step';

const Challenges: () => JSX.Element = () => {
  const title = <StaticPageTitle img={PageTitle} title="Desafios" />;

  const challenge01Subtitle = (
    <>
      Tire uma foto com seu amigo e/ou seus pais e poste na rede social deles.
      <br />
      Poste com a&nbsp;
    </>
  );

  const challenge02Subtitle = (
    <>
      Escolha uma das imagens para fazer uma careta igual ao do personagem
      e poste nas redes sociais com a&nbsp;
    </>
  );

  const challenge03Subtitle = (
    <>
      Grave um vídeo com a família, contando uma história engraçada
      que tenha acontecido e postar nas redes sociais dos seus pais usando a&nbsp;
    </>
  );

  const challenge04Subtitle = (
    <>
      Faça um desenho bem bonito da Masha e o Urso e poste nas redes sociais usando a&nbsp;
    </>
  );

  const [steps] = useState<Step[]>([
    {
      id: 1,
      title: 'Desafio 01',
      subtitle: challenge01Subtitle,
      children: <SmartPhoneImage />,
    },
    {
      id: 2,
      title: 'Desafio 02',
      subtitle: challenge02Subtitle,
      children: (
        <MashaImages>
          <CardMasha1 />
          <CardMasha2 />
          <CardMasha3 />
          <CardMasha4 />
        </MashaImages>
      ),
    },
    {
      id: 3,
      title: 'Desafio 03',
      subtitle: challenge03Subtitle,
      children: <SmartPhoneImage2 />,
    },
    {
      id: 4,
      title: 'Desafio 04',
      subtitle: challenge04Subtitle,
      children: <MashaImage />,
    },
  ]);
  const [currentStep, setCurrentStep] = useState<number>(0);

  return (
    <StaticPage title={title}>
      <Container>
        <ContentContainer>
          <Subtitle>
            Masha e o Urso são melhores amigos! E os seus, quem são? Será que
            você consegue completar esses desafios com eles?
          </Subtitle>
          <ChallengeTitle>{steps[currentStep].title}</ChallengeTitle>
          <ChallengeContent>
            {steps[currentStep].subtitle}
            <strong>#desafiomashaeourso</strong>
          </ChallengeContent>
          {steps[currentStep].children}
          <Stepper
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </ContentContainer>
      </Container>
    </StaticPage>
  );
};

export default Challenges;
