import React from 'react';

import Navbar from 'react-bootstrap/Navbar';

import MashaLogo from '../../MashaLogo';
import buildRoute from '../../../../services/route-builder';

const NavbarBrand = (props: { className?:string }) => {
  const query = window.location.search;

  const { className } = props;
  return (
    <Navbar.Brand className={className} href={`${buildRoute('/', query)}`}>
      <MashaLogo />
    </Navbar.Brand>
  );
};

NavbarBrand.defaultProps = { className: '' };

export default NavbarBrand;
