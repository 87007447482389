import styled from 'styled-components';

export const MazeControlsContainer = styled.div`
  position: relative;
  margin-left: -8%;
`;

export const DirectionButtonContainer = styled.div`
  width: 60px;
  position: relative;

  @media (min-width: 1440px) {
    width: 70px;
  }
`;

export const RestartButton = styled.img`
  cursor: pointer;
  width: 80%;
  
  @media (min-width: 1440px) {
    width: 100%;
  }
`;

export const RestartButtonContainer = styled.div`
  margin-top: 10%;
  text-align: center;
`;

export const StatusLabelContainer = styled.div`
  margin-top: 10%;
  text-align: center;
  font-size: 1.4rem;
`;
