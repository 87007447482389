import React from 'react';
import { RouteComponentProps } from 'react-router';
import FormLogin from './Form';

import { BgLogin, LoginContainer, LogoBemVindo } from './styles';

import BemVindoLogo from '../../assets/images/login/welcome.png';

const Login: (props: RouteComponentProps) => JSX.Element = (props: RouteComponentProps) => (
  <BgLogin>
    <LoginContainer>
      <LogoBemVindo alt="Bem Vindo" src={BemVindoLogo} />
      <FormLogin {...props} />
    </LoginContainer>
  </BgLogin>
);

export default Login;
