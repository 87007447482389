import React from 'react';
import Card from '../Card';

import { Board } from './styles';

interface Props {
  list: { id: number, img: any, description: string }[];
  visibleItems: any[];
  setVisibleItems: React.Dispatch<React.SetStateAction<any[]>>;
  finishedItems: number[];
  checkItems: (firstIndex: number, secondIndex: number) => void
}

const Grid: React.FC<Props> = ({
  list,
  visibleItems,
  setVisibleItems,
  finishedItems,
  checkItems,
}: Props) => (
  <Board className="container">
    <div className="row">
      {list.map((item, index) => (
        <Card
          key={item.id}
          showCard={visibleItems.includes(index) || finishedItems.includes(index)}
          onClick={() => {
            if (!finishedItems.includes(index)) {
              switch (visibleItems.length) {
                case 0:
                  setVisibleItems([index]);
                  break;
                case 1:
                  if (visibleItems[0] !== index) {
                    setVisibleItems(visibleItems.concat(index));
                    checkItems(visibleItems[0], index);
                  }
                  break;
                case 2:
                  setVisibleItems([index]);
                  break;
                default:
                  setVisibleItems([]);
              }
            }
          }}
          imgSource={item.img}
          imgDesc={item.description}
        />
      ))}
    </div>
  </Board>
);

export default Grid;
