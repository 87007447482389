import React from 'react';

import { Container, TitleContainer, PlayButton } from './styles';

interface Props {
  startGame: () => void
}

const StartGame: React.FC<Props> = ({ startGame }: Props) => (
  <Container>
    <TitleContainer />
    <PlayButton onClick={startGame}>Jogar</PlayButton>
  </Container>

);

export default StartGame;
