import React from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import NavbarBrand from '../NavbarBrand';
import NavbarContainerMobile from './styles';

import buildRoute from '../../../../services/route-builder';

const DefaultNavbarComponent: () => JSX.Element = () => {
  const query = window.location.search;

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <NavbarContainerMobile>
          <NavbarBrand className="me-0" />
        </NavbarContainerMobile>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <Nav.Link href={buildRoute('/', query)}>Início</Nav.Link>
            <Nav.Link href={buildRoute('/desafios', query)}>Desafios</Nav.Link>

            <NavDropdown title="Atividades" id="basic-nav-dropdown">
              <NavDropdown.Item href={buildRoute('/atividades/encontre-o-caminho', query)}>Encontre o Caminho</NavDropdown.Item>
              <NavDropdown.Item href={buildRoute('/atividades/ligue-os-pontos', query)}>Ligue os Pontos</NavDropdown.Item>
              <NavDropdown.Item href={buildRoute('/atividades/jogo-da-velha', query)}>Jogo da Velha</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Jogos" id="basic-nav-dropdown">
              <NavDropdown.Item href={buildRoute('/jogos/caca-ao-tesouro', query)}>Caça ao Tesouro</NavDropdown.Item>
              <NavDropdown.Item href={buildRoute('/jogos/jogo-musical-da-memoria', query)}>Jogo Musical da Memória</NavDropdown.Item>
              <NavDropdown.Item href={buildRoute('/jogos/jogo-da-memoria', query)}>Jogo da Memória</NavDropdown.Item>
              <NavDropdown.Item href={buildRoute('/jogos/pegue-as-frutas', query)}>Pegue as Frutas</NavDropdown.Item>
              <NavDropdown.Item href={buildRoute('/jogos/super-masha', query)}>Super Masha</NavDropdown.Item>
            </NavDropdown>

            <NavbarBrand className="d-none d-lg-block" />

            <Nav.Link href={buildRoute('/oficinas', query)}>Oficinas</Nav.Link>
            <Nav.Link href={buildRoute('/episodios', query)}>Episódios</Nav.Link>
            <Nav.Link href={buildRoute('/parceiros', query)}>Parceiros</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DefaultNavbarComponent;
