import React from 'react';

import GameControlsContainer from './styles';

export default (props: { children: React.ReactElement }) => {
  const { children } = props;
  return (
    <GameControlsContainer>{children}</GameControlsContainer>
  );
};
