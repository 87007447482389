import styled from 'styled-components';

export const FooterStyled = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
`;

export const Logo = styled.img`
  width: 30px;
`;
