import styled from 'styled-components';
import BearImage from '../../../../assets/images/connect-the-dots/bear.png';

export const Image = styled.img.attrs(() => ({
  alt: 'Bear',
  src: BearImage,
}))`
  max-width: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding-right: 30px;
  padding-top: 4%;
  padding-bottom: 4%;
`;
