import React from 'react';

import LoginNavbar from './LoginNavbar';
import DefaultNavbar from './DefaultNavbar';
import { useAppSelector } from '../../../store/hooks';

const NavbarComponent: () => JSX.Element = () => {
  const token = useAppSelector((state) => state.login.token);
  const isLogged = !!token;

  return (
    <>
      {!isLogged && <LoginNavbar /> }
      {isLogged && <DefaultNavbar />}
    </>
  );
};

export default NavbarComponent;
