import styled from 'styled-components';

export default styled.div`
  height: 100%;
  padding-bottom: 50px;
  flex-grow: 1;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-top: 98px
  }
`;
