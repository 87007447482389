import React from 'react';

import { StyledScore, StyledLabel } from './styles';

interface Props {
  frutasColetadas: number
}

const Score: React.FC<Props> = ({ frutasColetadas }: Props) => (
  <>
    <StyledScore>{frutasColetadas}</StyledScore>
    <StyledLabel>FRUTAS COLETADAS</StyledLabel>
  </>
);

export default Score;
