import React from 'react';
import {
  GridCard, CardHidden, CardShow, CardContainer,
} from './styles';

export {};
interface Props {
  showCard: boolean;
  imgSource: any;
  imgDesc: string;
  onClick: () => void
}

const Card: React.FC<Props> = ({
  showCard, imgSource, imgDesc, onClick,
}: Props) => (
  <GridCard className="col-3 mb-2" onClick={onClick} showing={showCard}>
    <CardContainer className="inner">
      <CardShow
        src={imgSource}
        alt={imgDesc}
      />
      <CardHidden />
    </CardContainer>
  </GridCard>
);

export default Card;
