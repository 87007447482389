import React, { useState } from 'react';

import Question from '../../../types/Episodes/Question';

import { ContainerAnswer, ButtonAnswer } from './styles';

const QuestionContainer = (props: { questionRow: Question, index: number }) => {
  const { questionRow, index } = props;
  const numQuestion = index + 1;
  const question = `${numQuestion}) ${questionRow.question}`;

  const [respondido, setRespondido] = useState(false);

  const selectAnswer = (e: React.SyntheticEvent<HTMLButtonElement, MouseEvent>, answer: any) => {
    if (!respondido) {
      setRespondido(true);

      if (answer.correct) {
        e.currentTarget.classList.add('correct-answer');
      } else {
        e.currentTarget.classList.add('wrong-answer');
      }
    }
  };

  return (
    <ContainerAnswer>
      <p className="mb-0">{question}</p>
      {
        questionRow.answers.map((a) => (
          <ButtonAnswer onClick={(e) => { selectAnswer(e, a); }}>{a.description}</ButtonAnswer>
        ))
      }
    </ContainerAnswer>
  );
};

export default QuestionContainer;
