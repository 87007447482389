import React from 'react';

import MashaImg from '../../../assets/images/maze/masha.png';
import TitleImg from '../../../assets/images/maze/title.png';

import {
  ControlsContainer,
  MashaContainer,
  MashaImage,
  TitleImage,
  PlayButton,
  TitleContainer,
  GroupContainer,
} from './styles';

interface Props {
  onStartClick: () => void
}

const StartGame: React.FC<Props> = ({ onStartClick }: Props) => (
  <>
    <MashaContainer>
      <MashaImage src={MashaImg} />
    </MashaContainer>
    <ControlsContainer>
      <GroupContainer>
        <TitleContainer>
          <TitleImage src={TitleImg} alt="Encontre o caminho" />
        </TitleContainer>
        <PlayButton onClick={onStartClick}>Jogar</PlayButton>
      </GroupContainer>
    </ControlsContainer>
  </>

);

export default StartGame;
