import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const ModalLGPD = () => {
  const [show, setShow] = useState(true);
  const [aceitouTermos, setAceitouTermos] = useState(false);

  const handleHide = () => setShow(false);
  const handleTermos = (e:any) => setAceitouTermos(e.target.checked);

  const handleClick = () => {
    if (aceitouTermos) {
      localStorage.setItem('opt-in', 'true');
      setShow(false);
    }
  };

  useEffect(() => {
    const optIn = localStorage.getItem('opt-in');

    if (optIn) {
      setShow(false);
      setAceitouTermos(true);
    }
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <h2 className="mb-0">Política de Privacidade</h2>
      </Modal.Header>
      <Modal.Body>
        <div className="my-4">
          Nós utilizamos cookies essenciais e
          tecnologias semelhantes de acordo com a nossa Política de Privacidade.
          Para continuar navegando, você declara que está ciente e concorda com estas condições.
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check>
              <Form.Check.Input className="me-1" type="checkbox" onChange={handleTermos} />
              <Form.Label>
                Li e aceito as condições da&nbsp;
                <a target="_blank" href={`${process.env.PUBLIC_URL}/politica_privacidade.pdf`} rel="noreferrer">política de privacidade</a>
                .
              </Form.Label>
            </Form.Check>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button disabled={!aceitouTermos} className="btn btn-amarelo" type="button" onClick={handleClick}>Aceitar</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalLGPD;
