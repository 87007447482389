import React from 'react';
import BearContainer from './BearContainer';

import {
  Container, TitleContainer, TitleImg, PlayButton,
} from './styles';

interface Props {
  startGame: () => void
}

const StartGame: React.FC<Props> = ({ startGame }: Props) => (
  <>
    <BearContainer />
    <Container>
      <TitleContainer>
        <TitleImg />
        <PlayButton onClick={startGame}>Jogar</PlayButton>
      </TitleContainer>
    </Container>
  </>

);

export default StartGame;
