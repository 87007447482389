import Direction from '../../types/MazeGame/Direction';

/* eslint-disable max-len */
export default [
  {
    direction: Direction.RIGHT,
    size: 0.1680,
    children: [
      {
        direction: Direction.UP,
        size: 0.1830,
        children: [
          {
            direction: Direction.LEFT,
            size: 0.0700,
            children: [
              {
                direction: Direction.DOWN,
                size: 0.0900,
                children: [
                  {
                    direction: Direction.LEFT,
                    size: 0.0650,
                    children: [
                      {
                        direction: Direction.UP,
                        size: 0.1000,
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            direction: Direction.RIGHT,
            size: 0.1250,
            children: [
              {
                direction: Direction.DOWN,
                size: 0.10,
                children: [
                  {
                    direction: Direction.RIGHT,
                    size: 0.0650,
                    children: [
                      {
                        direction: Direction.DOWN,
                        size: 0.0950,
                        children: [],
                      },
                      {
                        direction: Direction.RIGHT,
                        size: 0.0600,
                        children: [
                          {
                            direction: Direction.RIGHT,
                            size: 0.0600,
                            children: [
                              {
                                direction: Direction.UP,
                                size: 0.0950,
                                children: [],
                              },
                              {
                                direction: Direction.DOWN,
                                size: 0.0950,
                                children: [],
                              },
                            ],
                          },
                          {
                            direction: Direction.DOWN,
                            size: 0.1850,
                            children: [
                              {
                                direction: Direction.LEFT,
                                size: 0.1300,
                                children: [
                                  {
                                    direction: Direction.UP,
                                    size: 0.0950,
                                    children: [
                                      {
                                        direction: Direction.LEFT,
                                        size: 0.0700,
                                        children: [
                                          {
                                            direction: Direction.UP,
                                            size: 0.0950,
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    direction: Direction.DOWN,
                                    size: 0.0900,
                                    children: [
                                      {
                                        direction: Direction.LEFT,
                                        size: 0.0650,
                                        children: [
                                          {
                                            direction: Direction.LEFT,
                                            size: 0.0750,
                                            children: [],
                                          },
                                          {
                                            direction: Direction.DOWN,
                                            size: 0.0900,
                                            children: [
                                              {
                                                direction: Direction.LEFT,
                                                size: 0.1300,
                                                children: [
                                                  {
                                                    direction: Direction.UP,
                                                    size: 0.1800,
                                                    children: [
                                                      {
                                                        direction: Direction.LEFT,
                                                        size: 0.080,
                                                        children: [],
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    direction: Direction.DOWN,
                                                    size: 0.1000,
                                                    children: [
                                                      {
                                                        direction: Direction.RIGHT,
                                                        size: 0.1250,
                                                        children: [
                                                          {
                                                            direction: Direction.DOWN,
                                                            size: 0.0900,
                                                            children: [
                                                              {
                                                                direction: Direction.RIGHT,
                                                                size: 0.1300,
                                                                children: [
                                                                  {
                                                                    direction: Direction.UP,
                                                                    size: 0.0900,
                                                                    children: [
                                                                      {
                                                                        direction: Direction.RIGHT,
                                                                        size: 0.0600,
                                                                        children: [
                                                                          {
                                                                            direction: Direction.UP,
                                                                            size: 0.0950,
                                                                            children: [
                                                                              {
                                                                                direction: Direction.LEFT,
                                                                                size: 0.0700,
                                                                                children: [
                                                                                  {
                                                                                    direction: Direction.UP,
                                                                                    size: 0.1000,
                                                                                    children: [],
                                                                                  },
                                                                                ],
                                                                              },
                                                                              {
                                                                                direction: Direction.RIGHT,
                                                                                size: 0.1000,
                                                                                finish: true,
                                                                                children: [],
                                                                              },
                                                                            ],
                                                                          },
                                                                          {
                                                                            direction: Direction.DOWN,
                                                                            size: 0.0950,
                                                                            children: [
                                                                              {
                                                                                direction: Direction.RIGHT,
                                                                                size: 0.0650,
                                                                                children: [
                                                                                  {
                                                                                    direction: Direction.UP,
                                                                                    size: 0.0900,
                                                                                    children: [],
                                                                                  },
                                                                                ],
                                                                              },
                                                                            ],
                                                                          },
                                                                        ],
                                                                      },
                                                                    ],
                                                                  },
                                                                ],
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                      },
                                                      {
                                                        direction: Direction.DOWN,
                                                        size: 0.0850,
                                                        children: [
                                                          {
                                                            direction: Direction.LEFT,
                                                            size: 0.0700,
                                                            children: [
                                                              {
                                                                direction: Direction.UP,
                                                                size: 0.2800,
                                                                children: [],
                                                              },
                                                            ],
                                                          },
                                                          {
                                                            direction: Direction.RIGHT,
                                                            size: 0.0700,
                                                            children: [],
                                                          },
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                direction: Direction.RIGHT,
                                                size: 0.0600,
                                                children: [
                                                  {
                                                    direction: Direction.DOWN,
                                                    size: 0.1000,
                                                    children: [],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                direction: Direction.RIGHT,
                                size: 0.0650,
                                children: [
                                  {
                                    direction: Direction.DOWN,
                                    size: 0.0950,
                                    children: [
                                      {
                                        direction: Direction.LEFT,
                                        size: 0.0650,
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                direction: Direction.RIGHT,
                size: 0.1250,
                children: [],
              },
            ],
          },
        ],
      },
      {
        direction: Direction.DOWN,
        size: 0.0950,
        children: [
          {
            direction: Direction.RIGHT,
            size: 0.0700,
            children: [],
          },
        ],
      },
    ],
  },
];
