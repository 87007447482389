import React from 'react';

import BearImg from '../../../assets/images/healthy-food/bear.png';
import FrameImg from '../../../assets/images/healthy-food/frame.png';
import {
  StyledContainer, PlayButton, PlayAgainButton, Bear, Frame, Title,
} from './styles';

import Score from './Score';

interface Props {
  onClickStartButton: () => void;
  gameOver: boolean;
  frutasColetadas: number;
}

const StartGame: React.FC<Props> = ({ onClickStartButton, gameOver, frutasColetadas }: Props) => (
  <StyledContainer>
    <Bear alt="Urso" src={BearImg} />
    <Frame alt="" src={FrameImg} />

    {
      gameOver
        ? (
          <>
            <Score frutasColetadas={frutasColetadas} />
            <PlayAgainButton type="button" color="primary" onClick={onClickStartButton}>JOGAR NOVAMENTE</PlayAgainButton>
          </>
        )
        : (
          <>
            <Title>
              PEGUE
              <br />
              AS FRUTAS
            </Title>

            <PlayButton type="button" color="primary" onClick={onClickStartButton}>JOGAR</PlayButton>
          </>
        )
    }

  </StyledContainer>
);

export default StartGame;
