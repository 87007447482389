import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import BootstrapContainer from 'react-bootstrap/Container';

import StaticBackgroundImg from '../../../../assets/images/background-static.png';

export const BackgroundContainer = styled(Row)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0;
  background-image: url(${StaticBackgroundImg});
  background-repeat: repeat;
`;

export const Container = styled(BootstrapContainer)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
`;

export const ContentContainer = styled(BootstrapContainer)`
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  justify-content: center;
  padding: 2% 6% 2% 6%;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.30) 0 3px 8px;
  
  img {
    width: fit-content;
    align-self: center;
  }
`;
