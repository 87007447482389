import React from 'react';

import { createBrowserHistory } from 'history';
import { Router, Switch } from 'react-router-dom';

import { BrowserHistoryBuildOptions } from 'history/createBrowserHistory';
import Home from '../pages/Home';
import Maze from '../pages/Maze';
import Partners from '../pages/Partners';
import Episodes from '../pages/Episodes';
import Workshops from '../pages/Workshops';
import EpisodeQuiz from '../pages/EpisodeQuiz';

import TicTacToePage from '../pages/TicTacToe';
import SuperMashaPage from '../pages/SuperMasha';
import Games from '../pages/Games';
import Activities from '../pages/Activities';
import Challenges from '../pages/Challenges';
import MemoryGame from '../pages/MemoryGame';
import GeniusGame from '../pages/GeniusGame';
import TreasureHunt from '../pages/TreasureHunt';
import ConnectTheDots from '../pages/ConnectTheDots';
import HealthyEatingGame from '../pages/HealthyEatingGame';
import Login from '../pages/Login';
import ProtectedRoute from './ProtectedRoute';
import LoginRoute from './LoginRoute';

const browserHistoryOptions: BrowserHistoryBuildOptions = {};

if (process.env.REACT_APP_BASENAME) {
  browserHistoryOptions.basename = process.env.REACT_APP_BASENAME;
}

const history = createBrowserHistory(browserHistoryOptions);

const Routes = () => (
  <Router history={history}>
    <Switch>
      <LoginRoute exact path="/login" component={Login} />
      <ProtectedRoute exact path="/" component={Home} />
      <ProtectedRoute exact path="/desafios" component={Challenges} />
      <ProtectedRoute exact path="/atividades" component={Activities} />
      <ProtectedRoute exact path="/atividades/encontre-o-caminho" component={Maze} />
      <ProtectedRoute exact path="/atividades/ligue-os-pontos" component={ConnectTheDots} />
      <ProtectedRoute exact path="/atividades/jogo-da-velha" component={TicTacToePage} />
      <ProtectedRoute exact path="/jogos" component={Games} />
      <ProtectedRoute exact path="/jogos/caca-ao-tesouro" component={TreasureHunt} />
      <ProtectedRoute exact path="/jogos/jogo-musical-da-memoria" component={GeniusGame} />
      <ProtectedRoute exact path="/jogos/jogo-da-memoria" component={MemoryGame} />
      <ProtectedRoute exact path="/jogos/pegue-as-frutas" component={HealthyEatingGame} />
      <ProtectedRoute exact path="/jogos/super-masha" component={SuperMashaPage} />
      <ProtectedRoute exact path="/oficinas" component={Workshops} />
      <ProtectedRoute exact path="/episodios" component={Episodes} />
      <ProtectedRoute exact path="/episodios/:id" component={EpisodeQuiz} />
      <ProtectedRoute exact path="/parceiros" component={Partners} />
    </Switch>
  </Router>
);

export default Routes;
