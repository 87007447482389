import styled from 'styled-components';

export default styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  top: 50%;
  margin-top: -100px;
`;
