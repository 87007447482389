import styled from 'styled-components';

import StyledProps from './styled-props';
import StarImage from '../../../../assets/images/genius-game/star.png';

const gap = '5px';

export default styled.button<StyledProps>`
  border: none;
  cursor: pointer;
  display: block;
  width: 150px;
  height: 150px;
  margin: ${gap};

  background-image: url(${StarImage});
  background-color: ${(props) => (props.active ? '#FFFFFF' : `${props.color}`)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  :disabled {
    cursor: not-allowed;
  }
  
  :hover:not([disabled]) {
    background-color: ${'#FFFFFF'};
  }

  @media (min-width: 1441px) {
    width: 200px;
    height: 200px;
  }
`;
