import styled from 'styled-components';

export const LoginFooter = styled.div`
  width: 100%;
  height: 180px;
  background-color: #FFFFFF;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;

export const TextContainer = styled.div`
  margin: 1rem 0 0 0;
`;

export const MainLabel = styled.span`
  color: #555555;
  font-size: 1rem;
`;

export const SecondaryLabel = styled.span`
  color: #7d7d83;
  font-size: 0.75rem;
`;
