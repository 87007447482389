import styled, { keyframes } from 'styled-components';
import TitleImage from '../../../assets/images/super-masha/banner.png';
import Button from '../../../assets/images/super-masha/play-button.png';
import CloudsImage from '../../../assets/images/super-masha/clouds.png';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 100%;
`;

/* export const Clouds = styled.div``; */

export const Title = styled.img.attrs({
  alt: 'Super Masha',
  src: TitleImage,
})`
  width: 400px;
`;

export const ButtonContainer = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const PlayButton = styled.img.attrs({
  alt: 'Jogar Super Masha',
  src: Button,
})`
  width: 200px;
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em;
    transform: translateY(-0.25em);
  }
`;

const animatedBackground = keyframes`
  from { background-position: 0 0; }
  to { background-position: -500px 0; }
`;

export const Clouds = styled.div`
  background-image: url(${CloudsImage});
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80%;
  flex-direction: column;
  background-position: 0px 0px;
  animation: ${animatedBackground} 20s linear infinite;
`;
