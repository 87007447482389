import styled from 'styled-components';

export default styled.div`
  display: none;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 1023px) {
    display: flex;
  }
`;
