/* eslint-disable react/prop-types */
import React from 'react';
import {
  ModalBlock,
  ModalContainer,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Button,
  ModalFooter,
} from './styles';

interface Props {
  active: boolean,
  restartGame: () => void,
}

const GameOverModal: React.FC<Props> = ({
  active, restartGame,
}) => (
  <>
    {active && (
    <ModalBlock>
      <ModalOverlay />
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>GAME OVER</ModalTitle>
        </ModalHeader>
        <ModalFooter>
          <Button onClick={() => restartGame()}>Reiniciar</Button>
        </ModalFooter>
      </ModalContainer>
    </ModalBlock>
    )}
  </>
);
export default GameOverModal;
