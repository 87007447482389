import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import StyledProps from './styled-props';

interface GameContainerProps {
  fullWidth?: boolean;
}

export const Container = styled(Row)<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 148px);
  flex-grow: 1;

  ${({ fixedHeight }) => fixedHeight && `
    height: calc(100vh - 148px);
  `}
  
  ${({ backgroundimage, repeat }) => backgroundimage && `
    background-image: url('${backgroundimage}');
    background-repeat: ${repeat || 'repeat'};
    background-position: right top;
  `}

  ${({ background }) => background && `
    background-color: ${background};
  `}

  ${({ fullWidth }) => fullWidth && `
    padding: 0;
  `}
`;

/* export const GameContainer = styled(Row)<GameContainerProps>` */

export const GameContainer = styled.div<GameContainerProps>`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  ${({ fullWidth }) => fullWidth && `
    padding: 0;
  `}
`;
