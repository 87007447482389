import React from 'react';

import Button from '../../../assets/images/treasure-hunt/button.png';

const ButtonBaixeAsPistas = () => (
  <a target="_blank" href={`${process.env.PUBLIC_URL}/caca-ao-tesouro-pistas.pdf`} rel="noreferrer">
    <img alt="Baixe as pistas" src={Button} />
  </a>
);

export default ButtonBaixeAsPistas;
