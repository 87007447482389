import React, { useState } from 'react';

import GameScene from '../Games/GameScene';
import MainGameScreen from './GameScreen';
import StartScreen from './StartGame';

import bg from '../../assets/images/healthy-food/background.png';

const HealthyEating = () => {
  const [startGame, setGameStart] = useState<boolean>(false);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [frutasColetadas, setFrutasColetadas] = useState<number>(0);

  const onClickStartGame = () => {
    if (gameOver) {
      setFrutasColetadas(0);
    }

    setGameStart(true);
  };

  const onGameOver = (frutas: number) => {
    setGameOver(true);
    setFrutasColetadas(frutas);
    setGameStart(false);
  };

  const gameScreen = <MainGameScreen onGameOver={onGameOver} frutasColetadas={frutasColetadas} />;
  const startGameScreen = (
    <StartScreen
      onClickStartButton={onClickStartGame}
      gameOver={gameOver}
      frutasColetadas={frutasColetadas}
    />
  );

  return (
    <GameScene
      backgroundimage={bg}
      startScreen={startGameScreen}
      gameScreen={gameScreen}
      gameStarted={startGame}
      fullHeight
    />
  );
};

export default HealthyEating;
