import React from 'react';

import { ButtonMenuContainer, ImgStyled } from './styles';

const ButtonMenu = (props: { src?:string, alt?:string }) => {
  const { src, alt } = props;
  return (
    <ButtonMenuContainer>
      <ImgStyled alt={alt} src={src} />
    </ButtonMenuContainer>
  );
};

ButtonMenu.defaultProps = { src: '' };

export default ButtonMenu;
