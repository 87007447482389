import React from 'react';

import { RouteComponentProps } from 'react-router';
import Home from '../../components/Home';

type HomePageType = (props: RouteComponentProps) => JSX.Element;

const HomePage: HomePageType = (props: RouteComponentProps) => <Home {...props} />;

export default HomePage;
