import React from 'react';

import {
  Container, TitleContainer, GroupContainer, TitleImg, PlayButton,
} from './styles';
import MashaContainer from './MashaContainer';

interface Props {
  startGame: () => void
}

const StartGame: React.FC<Props> = ({ startGame }: Props) => (
  <>
    <MashaContainer />
    <Container>
      <GroupContainer>
        <TitleContainer>
          <div className="d-flex flex-column">
            <TitleImg />
            <PlayButton onClick={startGame}>Jogar</PlayButton>
          </div>
        </TitleContainer>
      </GroupContainer>
    </Container>
  </>

);

export default StartGame;
