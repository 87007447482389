import styled from 'styled-components';

export const ContainerAnswer = styled.div`
    width: 100%;
    display: block;
    margin-bottom: 1.5rem; 
`;

export const ButtonAnswer = styled.button`
    width: 100%;
    display:block;
    max-width: 360px;
    background-color: #fe23a5;
    color: #FFF;
    outline: none;
    border: none;
    margin: .5rem auto;
    border-radius: 20px;
    font-weight: bold;
    text-transform: uppercase;

    :hover {
        background-color: #fe23a5;
        color: #FFF;
    }
`;
